import { useEffect, useMemo } from 'react';
import { useAdvancedTranslation } from '@givelify/givelify-ui';
import { GivelifyForm } from '@givelify/ui';
import { isValidPhoneNumber } from '@givelify/utils';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { isZipCode } from 'utils/validateZipCode';
import * as yup from 'yup';
import { useCampusesContext } from '../CampusesProvider';
import { OrganizationSuggestionsResponse } from './SearchForm/types';
import { FormProps } from './types';
import View from './view';

const AddCampus = () => {
    const { addCampus, addCampusRequestState, setShowConfirmModal } =
        useCampusesContext();

    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.addForm',
    });

    const copy = useMemo(
        () => ({
            nicknameUnique: scopedTranslate('nicknameUnique'),
        }),
        [scopedTranslate],
    );

    const { doneeName } = useSelector((state: AppState) => ({
        doneeName: state.Donee.donee.name,
    }));

    const { watch, setValue, setError, reset, handleSubmit } =
        useFormContext<FormProps>();

    const vls = watch();
    const { currentStep } = vls;

    useEffect(() => {
        if (
            addCampusRequestState.type === 'REQUEST_ERROR' &&
            addCampusRequestState.error.status === 422
        ) {
            setError('nickname', {
                type: 'unique',
                message: copy.nicknameUnique,
            });
        }
    }, [addCampusRequestState, copy.nicknameUnique, setError]);

    useEffect(() => {
        if (currentStep === 0) {
            reset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const onConfirmOk = () => {
        setShowConfirmModal(false);
        addCampus({
            name: vls.name,
            address: vls.address,
            city: vls.city,
            nickname: vls.nickname,
            phone: vls.phone,
            state: vls.state,
            zip: vls.zip,
            campusId: vls.campusId,
            ypId: vls.ypId,
        });
    };

    const onCampusSelect = (value?: OrganizationSuggestionsResponse) => {
        const isCampusSelectedFromList = !!value;

        if (isCampusSelectedFromList) {
            setValue('campusId', value.id);
            setValue('address', value.source.address);
            setValue('city', value.source.city);
            setValue('phone', value.source.phone);
            setValue('zip', value.source.zip);
            setValue('name', value.source.name);
            setValue('state', value.source.state);
            setValue('ypId', value.source.ypId);
        } else {
            setValue('name', doneeName);
        }

        setValue('campusSelected', isCampusSelectedFromList);
        setValue('currentStep', 1);
    };

    return (
        <View
            onCampusSelect={onCampusSelect}
            onConfirm={onConfirmOk}
            onSubmit={handleSubmit(() => setShowConfirmModal(true))}
        />
    );
};

const WrappedModal = () => {
    const { showAddCampusModal } = useCampusesContext();
    if (!showAddCampusModal) return null;

    return <AddCampusForm />;
};

const AddCampusForm = () => {
    const { scopedTranslate } = useAdvancedTranslation({
        TRANSLATION_KEY: 'pages.settings.campuses.addCampusModal.addForm',
    });

    const copy = useMemo(
        () => ({
            required: scopedTranslate('required'),
            nameMaxLength: scopedTranslate('nameMaxLength'),
            nicknameUnique: scopedTranslate('nicknameUnique'),
            addressMaxLength: scopedTranslate('addressMaxLength'),
            invalidZip: scopedTranslate('invalidZip'),
            cityMaxLength: scopedTranslate('cityMaxLength'),
        }),
        [scopedTranslate],
    );

    const { setShowConfirmModal } = useCampusesContext();

    const formSchema: yup.SchemaOf<FormProps> = yup.object().shape({
        name: yup.string().required(copy.required).max(255, copy.nameMaxLength),
        nickname: yup.string().required(copy.required),
        address: yup
            .string()
            .required(copy.required)
            .max(255, copy.addressMaxLength),
        zip: yup
            .string()
            .required(copy.required)
            .test('validZip', copy.invalidZip, (value) =>
                isZipCode(value || ''),
            ),
        city: yup.string().required(copy.required).max(55, copy.cityMaxLength),
        state: yup.string().required(copy.required),
        phone: yup
            .string()
            .typeError(copy.required)
            .required(copy.required)
            .test('validPhone', 'Invalid phone number', (value) =>
                isValidPhoneNumber(value),
            ),
        authorized: yup.boolean().required(copy.required).isTrue(),
        campusId: yup.number(),
        campusSelected: yup.boolean(),
        ypId: yup.number().nullable(true),
        currentStep: yup.number(),
    });

    return (
        <GivelifyForm<FormProps>
            defaultValues={{
                currentStep: 0,
                authorized: false,
            }}
            onSubmit={() => setShowConfirmModal(true)}
            schema={formSchema}
            shouldUnregister={false}
        >
            <AddCampus />
        </GivelifyForm>
    );
};

export default WrappedModal;
