import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    FormProvider,
    useForm,
    UnpackNestedValue,
    FieldValues,
    DefaultValues,
    Mode,
    UseFormMethods,
} from 'react-hook-form';
import * as yup from 'yup';

export type GivelifyFormProps<Form extends FieldValues> = {
    mode?: Mode;
    reValidateMode?: Exclude<Mode, 'onTouched' | 'all'>;
    schema: yup.SchemaOf<Form>;
    defaultValues?: DefaultValues<Form>;
    onSubmit: (
        data: UnpackNestedValue<Form>,
        form: UseFormMethods<Form>,
    ) => void | Promise<void>;
    children?: React.ReactNode;
    dataTestId?: string;
    className?: string;
    shouldUnregister?: boolean;
};

export const GivelifyForm = <Form extends FieldValues>(
    props: GivelifyFormProps<Form>,
) => {
    const form = useForm<Form>({
        mode: props.mode ?? 'onChange',
        reValidateMode: props.reValidateMode ?? 'onChange',
        resolver: yupResolver(props.schema.defined()),
        defaultValues: props.defaultValues,
        shouldUnregister: props.shouldUnregister,
    });

    const { handleSubmit } = form;

    const doSubmit = (data: UnpackNestedValue<Form>) =>
        props.onSubmit(data, form);

    return (
        <FormProvider {...form}>
            <form
                className={props.className}
                data-testid={props.dataTestId}
                onSubmit={handleSubmit(doSubmit)}
            >
                {props.children}
            </form>
        </FormProvider>
    );
};
