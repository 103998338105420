import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyLink, GivelifyLabel } from '@givelify/ui';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import LegacyReportItem from './components/LegacyReportItem';
import {
    LegacyReportsHeader,
    LegacyReportsHeaderWrapper,
    LegacyReportsSubHeaderWrapper,
    ReportIcon,
    ReportList,
    ReportMainContent,
    ReportNotification,
    ReportRow,
    ViewPreviousReports,
} from './components/style';

interface ReportState {
    showInProgress: boolean;
    showError: boolean;
}

const LegacyReports: React.FCC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();
    const location = useLocation();
    const state = location.state as ReportState;
    const navigate = useNavigate();

    const ReportText = useMemo(
        () => ({
            dataExports: t('reports.dataExports'),
            dataExportsDefine: t(
                'reports.legacyReportsDashboard.dataExportsDefine',
            ),
            quickBooksHeader: t(
                'reports.legacyReportsDashboard.quickBooksHeader',
            ),
            quickBooksHeaderDefine: t(
                'reports.legacyReportsDashboard.quickBooksHeaderDefine',
            ),
            heading: t('reports.legacyReportsDashboard.heading'),
            historyLink: t(
                'reports.legacyReportsDashboard.viewPreviousReports',
            ),
            item: {
                donations: t('reports.legacyReportsDashboard.item.donations'),
                donationsDefine: t(
                    'reports.legacyReportsDashboard.item.donationsDefine',
                ),
                disbursements: t(
                    'reports.legacyReportsDashboard.item.disbursements',
                ),
                disbursementsDefine: t(
                    'reports.legacyReportsDashboard.item.disbursementsDefine',
                ),
                donors: t('reports.legacyReportsDashboard.item.donors'),
                donorsDefine: t(
                    'reports.legacyReportsDashboard.item.donorsDefine',
                ),
                quickBooksEnvelope: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelope',
                ),
                quickBooksEnvelopeDefine: t(
                    'reports.legacyReportsDashboard.item.quickBooksEnvelopeDefine',
                ),
                quickBooksDonor: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonor',
                ),
                quickBooksDonorDefine: t(
                    'reports.legacyReportsDashboard.item.quickBooksDonorDefine',
                ),
                importFriendly: t(
                    'reports.legacyReportsDashboard.item.importFriendly',
                ),
                importFriendlyDefine: t(
                    'reports.legacyReportsDashboard.item.importFriendlyDefine',
                ),
            },
            info: {
                donationsDataExportInfo: t(
                    'reports.legacyReportsDashboard.info.donationsDataExportInfo',
                ),
                quickBooksEnvelopeInfo: t(
                    'reports.legacyReportsDashboard.info.quickBooksEnvelopeInfo',
                ),
                quickBooksDonorInfo: t(
                    'reports.legacyReportsDashboard.info.quickBooksDonorInfo',
                ),
                disbursementsInfo: t(
                    'reports.legacyReportsDashboard.info.disbursementsInfo',
                ),
                donorsInfo: t('reports.legacyReportsDashboard.info.donorsInfo'),
                importFriendlyInfo: t(
                    'reports.legacyReportsDashboard.info.importFriendlyInfo',
                ),
            },
            gotoHistory: t('reports.gotoHistory'),
            generatingReport: t('reports.generatingReport'),
            viewHistory: t('reports.viewHistory'),
            error: t('error.500.heading'),
        }),
        [t],
    );

    useEffect(() => {
        if (state) {
            setShowInProgress(!!state.showInProgress);
            setShowError(!!state.showError);
        }
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToHistory = () => {
        navigate(PATH.REPORTS.REPORT_HISTORY);
    };
    const [showInProgress, setShowInProgress] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.ReportsOldDashboardTab}
        >
            <ReportNotification
                onClose={() => setShowInProgress(false)}
                open={showInProgress}
                text={
                    <GivelifyLabel variant="body1B">
                        {ReportText.generatingReport}
                        <GivelifyLink
                            onClick={goToHistory}
                            text={ReportText.viewHistory}
                            variant="body1B"
                        />
                    </GivelifyLabel>
                }
                variant="info"
            />
            <ReportNotification
                onClose={() => setShowError(false)}
                open={showError}
                text={ReportText.error}
                variant="error"
            />
            <Grid container>
                <LegacyReportsHeaderWrapper
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    lg={12}
                    xs={12}
                >
                    <Grid item md="auto" xs={12}>
                        <LegacyReportsHeader
                            text={ReportText.heading}
                            variant="heading1S"
                        />
                    </Grid>
                    <ViewPreviousReports item md="auto" xs={12}>
                        <GivelifyLink
                            name="View Previous Reports"
                            onClick={goToHistory}
                            text={ReportText.historyLink}
                        />
                    </ViewPreviousReports>
                </LegacyReportsHeaderWrapper>
                <LegacyReportsSubHeaderWrapper
                    container
                    item
                    alignItems="center"
                    lg={12}
                    spacing={0.5}
                    wrap="nowrap"
                    xs={12}
                >
                    <Grid item>
                        <ReportIcon variant="data-exports" />
                    </Grid>
                    <Grid item>
                        <GivelifyLabel
                            marginBottom={0}
                            text={ReportText.dataExports}
                            variant="heading2S"
                        />
                        <GivelifyLabel
                            fontWeight={600}
                            sx={{
                                wordWrap: 'break-word',
                            }}
                            text={ReportText.dataExportsDefine}
                            variant="body1"
                        />
                    </Grid>
                </LegacyReportsSubHeaderWrapper>
                <ReportRow item lg={12} xs={12}>
                    <ReportMainContent container>
                        <LegacyReportItem
                            define={ReportText.item.donationsDefine}
                            icon={
                                <ReportIcon variant="hand-holding-usd-fill" />
                            }
                            id="legacy-donations"
                            include={ReportText.info.donationsDataExportInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DONATIONS}
                            name={ReportText.item.donations}
                        />
                        <LegacyReportItem
                            define={ReportText.item.disbursementsDefine}
                            icon={<ReportIcon variant="bank-deposits" />}
                            id="legacy-disbursements"
                            include={ReportText.info.disbursementsInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DISBURSMENTS}
                            name={ReportText.item.disbursements}
                        />
                        <LegacyReportItem
                            quickbook
                            define={ReportText.item.donorsDefine}
                            icon={<ReportIcon variant="donors-old" />}
                            id="legacy-donors"
                            include={ReportText.info.donorsInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_DONORS}
                            name={ReportText.item.donors}
                        />
                        <LegacyReportItem
                            quickbook
                            define={ReportText.item.importFriendlyDefine}
                            icon={<ReportIcon variant="import-friendly" />}
                            id="legacy-import-friendly"
                            include={ReportText.info.importFriendlyInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_IMPORT_FRIENDLY}
                            name={ReportText.item.importFriendly}
                        />
                    </ReportMainContent>
                </ReportRow>

                <ReportRow item lg={12} xs={12}>
                    <LegacyReportsSubHeaderWrapper
                        container
                        item
                        alignItems="center"
                        lg={12}
                        wrap="nowrap"
                        xs={12}
                    >
                        <ReportIcon variant="quickbooks" />
                        <div>
                            <GivelifyLabel
                                marginBottom={0}
                                text={ReportText.quickBooksHeader}
                                variant="heading2S"
                            />
                            <GivelifyLabel
                                wrap
                                fontWeight={600}
                                text={ReportText.quickBooksHeaderDefine}
                                variant="body1"
                            />
                        </div>
                    </LegacyReportsSubHeaderWrapper>
                    <ReportList container>
                        <LegacyReportItem
                            define={ReportText.item.quickBooksEnvelopeDefine}
                            icon={<ReportIcon variant="by-envelope-fill" />}
                            id="legacy-qb-envelopes"
                            include={ReportText.info.quickBooksEnvelopeInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_BY_ENVELOPE}
                            name={ReportText.item.quickBooksEnvelope}
                        />

                        <LegacyReportItem
                            define={ReportText.item.quickBooksDonorDefine}
                            icon={<ReportIcon variant="by-donor-fill" />}
                            id="legacy-qb-donor"
                            include={ReportText.info.quickBooksDonorInfo}
                            link={PATH.REPORTS.LEGACY_REPORTS_BY_DONOR}
                            name={ReportText.item.quickBooksDonor}
                        />
                    </ReportList>
                </ReportRow>
            </Grid>
        </TrackingProvider>
    );
};

export default LegacyReports;
