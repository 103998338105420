import React, { useEffect, useMemo, useState } from 'react';
import { GivelifyLink, GivelifyLabel } from '@givelify/ui';
import {
    TrackingProvider,
    PAGE_NAME,
    defaultNewDoneeDate,
    TimeFrameId,
    useInvokeApi,
    convertToDate,
} from '@givelify/utils';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAdvancedTranslation } from 'utils/i18n';
import { AppState } from '../../../store';
import { ReportHistory } from '../../../store/report/types';
import { isFeatureEnabled, Features } from '../../../utils/featureGating';
import {
    GenerateReportsWrapper,
    ReportIcon,
    ReportList,
    ReportMainContent,
    ReportNotification,
    ReportRow,
} from '../components/style';
import ReportItem from './ReportItem';

interface ReportState {
    reportType: string;
    startDate: Date;
    endDate: Date;
    report: string;
    format: string;
    timeFrameId: TimeFrameId;
}
const generateEndpoint = '/reports/generate';
const clearState = {
    reportType: '',
    startDate: '',
    endDate: '',
    report: '',
    timeFrameId: 'Custom dates',
};

const Reports: React.FCC = () => {
    const { PATH } = useGasRouterContext();
    const { t } = useAdvancedTranslation();
    const location = useLocation();
    const state = location.state as ReportState;
    const navigate = useNavigate();
    const { doneeId, enabledFeatures, doneeSignupDate, organizationType } =
        useSelector((state: AppState) => ({
            doneeId: state.Donee.donee.id,
            enabledFeatures: state.System.enabledFeatures,
            doneeSignupDate: convertToDate(state.Donee.campuses[0].signupDate), // we need HQ campus signup date
            organizationType: state?.Donee?.donee?.onboarding?.organizationType,
        }));
    const ReportText = useMemo(
        () => ({
            customReport: t('reports.customReport'),
            reportDefine: t('reports.reportDefine'),
            reports: t('reports.reports'),
            generateReports: t('reports.generateReports'),
            generate: t('reports.generate'),
            dataExports: t('reports.dataExports'),
            dataExportsDefine: t('reports.dataExportsDefine'),
            legacyReports: t('reports.legacyReports'),
            legacyReportsDefine: t('reports.legacyReportsDefine'),
            item: {
                donations: t('reports.item.donations'),
                donationsDefine: t('reports.item.donationsDefine'),
                donationsLegacyDefine: t('reports.item.donationsLegacyDefine'),
                disbursements: t('reports.item.disbursements'),
                disbursementsDefine: t('reports.item.disbursementsDefine'),
                bankDeposits: t('reports.item.bankDeposits'),
                bankDepositsDefine: t('reports.item.bankDepositsDefine'),
                donors: t('reports.item.donors'),
                donorsDefine: t('reports.item.donorsDefine'),
                quickbooksEnvelope: t('reports.item.quickbooksEnvelope.church'),
                quickbooksCampaign: t(
                    'reports.item.quickbooksEnvelope.nonprofit',
                ),
                quickbooksEnvelopeDefine: t(
                    'reports.item.quickbooksEnvelopeDefine',
                ),
                quickbooksDonor: t('reports.item.quickbooksDonor'),
                quickbooksDonorDefine: t('reports.item.quickbooksDonorDefine'),
            },
            info: {
                donationsDataExportInfo: t(
                    'reports.info.donationsDataExportInfo',
                ),
                bankDepositsInfo: t('reports.info.bankDepositsInfo'),
                donationByEnvelopeInfo: t(
                    'reports.info.donationByEnvelopeInfo',
                ),
                donationByDonorsInfo: t('reports.info.donationByDonorsInfo'),
                donationLegacyInfo: t('reports.info.donationLegacyInfo'),
                disbursementsInfo: t('reports.info.disbursementsInfo'),
                donorsInfo: t('reports.info.donorsInfo'),
            },
            gotoHistory: t('reports.gotoHistory'),
            generatingReport: t('reports.generatingReport'),
            viewHistory: t('reports.viewHistory'),
            error: t('error.500.heading'),
        }),
        [t],
    );

    const features = useMemo(
        () => ({
            donations: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_DONATIONS,
            ),
            bankDeposits: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_BANK_DEPOSITS,
            ),
            donors: isFeatureEnabled(enabledFeatures, Features.REPORTS_DONORS),
            importFriendly: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_IMPORT_FRIENDLY,
            ),
            quickbooksEnvelope: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_QUICKBOOKS_ENVELOPE,
            ),
            quickbooksDonor: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_QUICKBOOKS_DONOR,
            ),
            reconciliation: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_RECONCILIATION,
            ),
            disbursements: isFeatureEnabled(
                enabledFeatures,
                Features.REPORTS_DISBURSEMENTS,
            ),
        }),
        [enabledFeatures],
    );

    const [generateRequestState, generateRequest] =
        useInvokeApi<ReportHistory>();

    useEffect(() => {
        if (state && state.startDate && state.endDate) {
            generateRequest('POST', generateEndpoint, {
                doneeId,
                startDate: state?.startDate,
                endDate: state?.endDate,
                reportType: state?.report,
                fileType: state?.format,
                timeFramePickerText: state?.timeFrameId,
            });
        }
        //run on first load
        //eslint-disable-next-line
    }, []);

    const goToHistory = () => navigate(PATH.REPORTS.REPORT_HISTORY);
    const [showInProgress, setShowInProgress] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    useEffect(() => {
        if (generateRequestState.type === 'REQUEST_START') {
            setShowInProgress(true);
        } else if (generateRequestState.type === 'REQUEST_SUCCESS') {
            navigate(PATH.REPORTS.GENERATE_REPORTS(), {
                state: clearState,
                replace: true,
            });
        } else if (generateRequestState.type === 'REQUEST_ERROR') {
            navigate(PATH.REPORTS.GENERATE_REPORTS(), {
                state: clearState,
                replace: true,
            });
            setShowError(true);
        }
    }, [generateRequestState, navigate, PATH.REPORTS]);

    const closeInProgress = () => {
        setShowInProgress(false);
    };

    return (
        <TrackingProvider
            trackPageVisit
            pageName={PAGE_NAME.ReportsGenerateTab}
        >
            <ReportNotification
                onClose={closeInProgress}
                open={showInProgress}
                text={
                    <GivelifyLabel variant="body1B">
                        {ReportText.generatingReport}
                        <GivelifyLink
                            onClick={goToHistory}
                            text={ReportText.viewHistory}
                            variant="body1B"
                        />
                    </GivelifyLabel>
                }
                variant="info"
            />
            <ReportNotification
                onClose={() => setShowError(false)}
                open={showError}
                text={ReportText.error}
                variant="error"
            />
            <GenerateReportsWrapper container>
                <Grid item lg={12} xs={12}>
                    <GivelifyLabel
                        fontWeight="bold"
                        marginBottom="8px"
                        text={ReportText.dataExports}
                        variant="heading2S"
                    />
                    <GivelifyLabel
                        fontWeight="700"
                        text={ReportText.dataExportsDefine}
                        variant="body1"
                    />
                </Grid>
                <ReportRow item lg={12} xs={12}>
                    <ReportMainContent container>
                        {features.donations && (
                            <ReportItem
                                define={ReportText.item.donationsDefine}
                                icon={<ReportIcon variant="computer" />}
                                include={
                                    ReportText.info.donationsDataExportInfo
                                }
                                link={PATH.REPORTS.REPORT_DONATIONS}
                                name={ReportText.item.donations}
                                testId="donations-report"
                            />
                        )}
                        {features.bankDeposits && (
                            <ReportItem
                                define={ReportText.item.bankDepositsDefine}
                                icon={
                                    <ReportIcon variant="insititution-new-checkmark" />
                                }
                                include={ReportText.info.bankDepositsInfo}
                                link={PATH.REPORTS.REPORT_BANK_DEPOSITS}
                                name={ReportText.item.bankDeposits}
                                testId="bank-deposits-report"
                            />
                        )}
                        {features.quickbooksEnvelope && (
                            <ReportItem
                                quickbook
                                define={
                                    ReportText.item.quickbooksEnvelopeDefine
                                }
                                icon={<ReportIcon variant="mail" />}
                                include={ReportText.info.donationByEnvelopeInfo}
                                link={PATH.REPORTS.DONATION_BY_ENVELOPE}
                                name={
                                    organizationType === 'church'
                                        ? ReportText.item.quickbooksEnvelope
                                        : ReportText.item.quickbooksCampaign
                                }
                                testId="donations-by-envelope-report"
                            />
                        )}
                        {features.quickbooksDonor && (
                            <ReportItem
                                quickbook
                                define={ReportText.item.quickbooksDonorDefine}
                                icon={<ReportIcon variant="donor" />}
                                include={ReportText.info.donationByDonorsInfo}
                                link={PATH.REPORTS.DONATIONS_BY_DONOR}
                                name={ReportText.item.quickbooksDonor}
                                testId="donations-by-donor-report"
                            />
                        )}
                    </ReportMainContent>
                </ReportRow>

                {doneeSignupDate < defaultNewDoneeDate && (
                    <ReportRow item lg={12} xs={12}>
                        {(features.donations ||
                            features.disbursements ||
                            features.donors) && (
                            <Grid item lg={12} xs={12}>
                                <GivelifyLabel
                                    fontWeight="bold"
                                    marginBottom="8px"
                                    text={ReportText.legacyReports}
                                    variant="heading2S"
                                />
                                <GivelifyLabel
                                    fontWeight="700"
                                    text={ReportText.legacyReportsDefine}
                                    variant="body1"
                                />
                            </Grid>
                        )}
                        <ReportList container>
                            {features.donations && (
                                <ReportItem
                                    isLegacy
                                    define={
                                        ReportText.item.donationsLegacyDefine
                                    }
                                    icon={
                                        <ReportIcon variant="heart-in-hand" />
                                    }
                                    include={ReportText.info.donationLegacyInfo}
                                    link={PATH.REPORTS.REPORT_DONATIONS_LEGACY}
                                    name={ReportText.item.donations}
                                    testId="donations-legacy-report"
                                />
                            )}
                            {features.disbursements && (
                                <ReportItem
                                    isLegacy
                                    define={ReportText.item.disbursementsDefine}
                                    icon={<ReportIcon variant="bank-reports" />}
                                    include={ReportText.info.disbursementsInfo}
                                    link={PATH.REPORTS.REPORT_DISBURSEMENTS}
                                    name={ReportText.item.disbursements}
                                    testId="disbursments-legacy-report"
                                />
                            )}
                            {features.donors && (
                                <ReportItem
                                    isLegacy
                                    define={ReportText.item.donorsDefine}
                                    icon={<ReportIcon variant="people" />}
                                    include={ReportText.info.donorsInfo}
                                    link={PATH.REPORTS.REPORT_DONORS}
                                    name={ReportText.item.donors}
                                    testId="donors-legacy-report"
                                />
                            )}
                        </ReportList>
                    </ReportRow>
                )}
            </GenerateReportsWrapper>
        </TrackingProvider>
    );
};

export default Reports;
