import { GivelifyLabel } from '@givelify/ui';
import { styled } from '@mui/material/styles';

export const AddPhotoButton = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2.25),
}));

export const HederContentWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('mobile')]: {
        flexDirection: 'column',
    },
}));

export const ConstrainedLabel = styled(GivelifyLabel)(({ theme }) => ({
    [theme.breakpoints.down('mobile')]: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: 'calc(100vw - 124px)',
    },
}));
