import React from 'react';
import { mergeClassNames } from '@givelify/givelify-ui';
import {
    DesignTokens,
    GivelifyButton,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/ui';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FileType, getFileName, getFileSize } from '../../../utils/fileState';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fileUploadStyles: ({
            status,
        }: {
            status: 'idle' | 'success' | 'error';
        }) => ({
            visibility: status === 'idle' ? 'hidden' : undefined,
            marginTop: 20,
            marginBottom: theme.spacing(3),
            '& .check-icon': {
                width: 17.59,
                height: 13.41,
                marginRight: 5,
            },
            '& .details': {
                display: 'flex',
                alignItems: 'center',
            },
            '& .inline': {
                display: 'inline-block',
            },
            '& .file-name': {
                maxWidth: 285,
                wordBreak: 'break-all',
                paddingRight: theme.spacing(2),
            },
            '& .strike-container': {
                width: 398,
                borderBottom: '1px solid #E3E3E3',
                paddingBottom: 5,
                display: 'inline-flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                '@media (max-width: 560px)': {
                    width: '100%',
                },
            },
        }),
    }),
);

interface FileUploadStateProps {
    onRemove: () => unknown;
    file: FileType; // string when there is an error
    hideOnIdle?: true;
    className?: string;
}

const FileUploadState: React.FCC<FileUploadStateProps> = ({
    file,
    onRemove,
    hideOnIdle,
    className,
}) => {
    const { fileUploadStyles } = useStyles({ status: file.status });

    if (hideOnIdle && file.status === 'idle') return null;

    if (file.status === 'success') {
        return (
            <div className={mergeClassNames(fileUploadStyles, className)}>
                <GivelifyIcon
                    className="check-icon"
                    color={DesignTokens.color.iconSuccess}
                    variant="check"
                />
                <div className="inline strike-container">
                    <GivelifyLabel
                        className="inline file-name"
                        text={getFileName(file.file)}
                        variant="body2"
                    />
                    <div className="details">
                        <GivelifyLabel
                            className="inline"
                            text={getFileSize(file.file.size)}
                            variant="body2"
                        />
                        <GivelifyButton onClick={onRemove} variant="icon">
                            <GivelifyIcon
                                color={DesignTokens.color.iconSecondary}
                                variant="file"
                            />
                        </GivelifyButton>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={fileUploadStyles}>
            <GivelifyIcon
                className="check-icon"
                color={DesignTokens.color.iconError}
                variant="close"
            />
            <div className="inline strike-container">
                <GivelifyLabel
                    className="inline file-name"
                    color="error"
                    text={file.file || ''}
                    variant="body2"
                />
            </div>
        </div>
    );
};

export default React.memo(FileUploadState);
