import React, {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useEffect,
} from 'react';
import { isBase64Image } from '@givelify/utils';
import { styled } from '@mui/material';
import heic2any from 'heic2any';
import { useTranslation } from 'react-i18next';
import { ImageWithDimensions } from '../@types/assets/onboarding';
import { I18N_NAMESPACE } from '../consts';
import FileUploadState from '../requiredInfo/components/documentUpload/FileUploadState';
import { MAX_CHEQUE_FILE_SIZE_IN_MB } from '../requiredInfo/components/documentUpload/UploadDocument';
import {
    allowedExtension,
    FileType,
    getSingleFile,
    initialFileType,
} from '../utils/fileState';
import { ImageUploadButtonProps } from './ImageUploadButtons';
import { ImageCropper } from '.';

export interface ImageButtonRef {
    clear: () => void;
    reset: () => void;
}
interface ImageButtonProps {
    id: string;
    aspectRatio: number;
    isRound?: true;
    Component: React.FCC<ImageUploadButtonProps>;
    initialImage: ImageWithDimensions | undefined;
    title: string | undefined;
    onChange: (image: ImageWithDimensions | undefined) => unknown;
    fileUploadStateClassName?: string;
    ref?: React.RefObject<ImageButtonRef>;
    isShowingUploadState?: boolean;
    applyButtonText?: string;
    changeButtonText?: string;
    deleteButtonText?: string;
    isBackdropClickDisabled?: boolean;
    keepOpen?: boolean;
    isOpen?: boolean;
    disabled?: boolean;
}

const allowedExtensions = ['png', 'jpeg', 'jpg', 'heic'];
const HiddenInput = styled('input')({
    display: 'none',
});
const ImageButtonComponent = (
    {
        id,
        Component,
        onChange,
        aspectRatio,
        isRound,
        initialImage,
        title,
        isShowingUploadState = true,
        applyButtonText,
        changeButtonText,
        deleteButtonText,
        isBackdropClickDisabled,
        keepOpen,
        isOpen,
        disabled,
    }: ImageButtonProps,
    forwardRef: React.ForwardedRef<ImageButtonRef>,
) => {
    const { t } = useTranslation(I18N_NAMESPACE);
    const [blob, setBlob] = useState<File | null>(null);
    const [file, setFile] = useState<FileType>(initialFileType);
    const [image, setImage] = useState<string | undefined>(initialImage?.url);
    const buttonRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [initialLogo, setInitialLogo] = useState<string>('');
    const [showSpinner, setShowSpinner] = useState<boolean>(false);

    const { uploadRequirementFailedText, fileNotSupported } = useMemo(() => {
        const maxSizeObject = { maxSize: MAX_CHEQUE_FILE_SIZE_IN_MB };
        return {
            uploadRequirementFailedText: t(
                'directDepositManualEntry.uploadRequirementFailedText',
                maxSizeObject,
            ),
            fileNotSupported: t('labels.fileNotSupported'),
        };
    }, [t]);

    const onApply = (image: ImageWithDimensions) => {
        if (image && blob) {
            setFile(
                getSingleFile({
                    maxSizeInMB: MAX_CHEQUE_FILE_SIZE_IN_MB,
                    errorText: uploadRequirementFailedText,
                    file: blob,
                    allowedExtensions,
                }),
            );
            onChange(image);

            keepOpen ? setShowSpinner(true) : setOpen(false);
        }
    };
    const onChangePicture = () => {
        if (buttonRef.current) {
            buttonRef.current.value = '';
            buttonRef.current.click();
        }
    };
    const onDelete = () => {
        setFile(initialFileType);
        setOpen(false);
        onChange(undefined);
        if (buttonRef.current) {
            buttonRef.current.value = '';
        }
    };
    const onHideUploadDetails = () => {
        setFile(initialFileType);
        setOpen(false);
        if (buttonRef.current) {
            buttonRef.current.value = '';
        }
    };
    const onUploadClick = () => {
        if (file.status === 'success') {
            setOpen(true);
        } else {
            buttonRef?.current?.click();
        }
    };
    const onCloseImageCropper = () => {
        setOpen(false);
        if (file.status !== 'success' && buttonRef.current) {
            buttonRef.current.value = '';
        }
    };
    const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const isValidFile = files && files.length > 0;
        if (!isValidFile) {
            return;
        }

        let file = files[0];
        if (!allowedExtension(allowedExtensions, file)) {
            setFile({ status: 'error', file: fileNotSupported });
            return;
        }

        const fullName = file.name;
        const lastIndex = fullName.lastIndexOf('.');
        const name = fullName.substring(0, lastIndex) || fullName;
        const extension = fullName.substring(lastIndex + 1);

        if (extension === 'heic') {
            setIsLoading(true);
            const converted = await heic2any({
                blob: file,
                toType: 'image/jpeg',
            });

            const convertedBlob = Array.isArray(converted)
                ? converted[0]
                : converted;

            file = new File([convertedBlob], `${name}.jpeg`, {
                type: 'image/jpeg',
            });
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImage(reader.result ? reader.result.toString() : undefined);
            setIsLoading(false);
        });
        reader.readAsDataURL(file);

        setBlob(file);
        setOpen(true);
    };

    useImperativeHandle(forwardRef, () => ({
        clear: onDelete,
        reset: onHideUploadDetails,
    }));

    useEffect(() => {
        if (initialImage?.url) {
            setInitialLogo(initialImage?.url);
        }
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isBase64Image(initialImage?.url) && isOpen === false) {
            setOpen(false);
            setShowSpinner(false);
        }
        //eslint-disable-next-line
    }, [isOpen]);

    return (
        <>
            <Component
                id={id}
                isImage={initialLogo ? true : false}
                isLoading={isLoading}
                onUploadClick={onUploadClick}
            />
            <HiddenInput
                ref={buttonRef}
                accept="image/x-png,image/jpg,image/jpeg,image/heic"
                disabled={disabled}
                onChange={onSelectFile}
                type="file"
            />
            {isShowingUploadState && (
                <FileUploadState hideOnIdle file={file} onRemove={onDelete} />
            )}
            {image && (
                <ImageCropper
                    applyButtonText={applyButtonText}
                    aspectRatio={aspectRatio}
                    changeButtonText={changeButtonText}
                    deleteButtonText={deleteButtonText}
                    isBackdropClickDisabled={isBackdropClickDisabled}
                    isLoading={showSpinner}
                    isOpen={open}
                    isRound={isRound}
                    onApply={onApply}
                    onChangePicture={onChangePicture}
                    onClose={onCloseImageCropper}
                    onDeletePicture={onDelete}
                    title={title}
                    url={image}
                />
            )}
        </>
    );
};

export const ImageButton: React.FCC<ImageButtonProps> = React.memo(
    forwardRef<ImageButtonRef, ImageButtonProps>(ImageButtonComponent),
);
