import React, { useEffect, useMemo } from 'react';
import {
    GivelifyButton,
    GivelifyColorPalette,
    GivelifyIcon,
    GivelifyLabel,
} from '@givelify/givelify-ui';
import { GivelifyFormDatePicker, GivelifyFormRadio } from '@givelify/ui';
import { formatWithTimezone, toISODate } from '@givelify/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery, useTheme } from '@material-ui/core';
import dayjs from 'dayjs';
import { FormProvider, useForm } from 'react-hook-form';
import { useAdvancedTranslation } from 'utils/i18n';
import * as yup from 'yup';
import { useEnvelopesContext } from '../../context/EnvelopesProvider';
import useStyles from './styles';
import { FormShowEnvelope, IsAlwaysValue, isAlwaysValues } from './types';

interface ShowEnvelopeModalContentProps {
    onCloseModal: () => void;
    envelopeId: number;
}

const ShowEnvelopeModalContent: React.FCC<ShowEnvelopeModalContentProps> = ({
    onCloseModal,
    envelopeId,
}) => {
    const {
        modalTitle,
        switchImage,
        switchArrowImg,
        switchContent,
        switchTitle,
        switchBottom,
        hideEnvelopeBtn,
        cancelBtn,
        editorContainer,
        alwaysRadio,
        specificDate,
        dateFieldLabelWrapper,
        selectDate,
        selectDateItem,
        mobileSmallTitle,
        mobileDate,
        hidden,
    } = useStyles();

    const {
        showEnvelope,
        showEnvelopeRequestState: state,
        inactiveEnvelopes,
    } = useEnvelopesContext();

    const inProgress = state === 'REQUEST_START';

    const { name, isDefault, start, end, alwaysOn } = useMemo(
        () => inactiveEnvelopes.data.find((e) => e.id === envelopeId),
        [inactiveEnvelopes, envelopeId],
    );
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const isAlways = alwaysOn === 'Always';

    const { t, at } = useAdvancedTranslation();
    const copy = useMemo(
        () => ({
            askToShow: at(
                'pages.settings.envelopes2.inactive-envelopes-tab.askToShow',
            ),
            askDesc: at(
                'pages.settings.envelopes2.inactive-envelopes-tab.askDesc',
            ),
            askSelect: t(
                'pages.settings.envelopes2.inactive-envelopes-tab.askSelect',
            ),
            previousShow: t(
                'pages.settings.envelopes2.inactive-envelopes-tab.previousShow',
                {
                    startDate: formatWithTimezone(start),
                    endDate: formatWithTimezone(end),
                },
            ),
            always: t('pages.settings.envelopes2.envelopes-editor.always'),
            custom: t(
                'pages.settings.envelopes2.envelopes-editor.specificDate',
            ),
            from: t('pages.settings.envelopes2.envelopes-editor.from'),
            invalidDate: t(
                'pages.settings.envelopes2.envelopes-editor.invalidDate',
            ),
            startDate: t(
                'pages.settings.envelopes2.envelopes-editor.start-date',
            ),
            to: t('pages.settings.envelopes2.envelopes-editor.to'),
            endDate: t('pages.settings.envelopes2.envelopes-editor.end-date'),
            requireStartDate: t(
                'pages.settings.envelopes2.envelopes-editor.require-start-date',
            ),
            requireEndDate: t(
                'pages.settings.envelopes2.envelopes-editor.require-end-date',
            ),
            startDateAfterToday: t(
                'pages.settings.envelopes2.envelopes-editor.start-date-after-today',
            ),
            startDateBeforeEndDate: t(
                'pages.settings.envelopes2.envelopes-editor.start-date-before-end-date',
            ),
            endDateAfterStartDate: t(
                'pages.settings.envelopes2.envelopes-editor.end-date-after-start-date',
            ),
            cancel: t('labels.cancel'),
            showEnvelope: at(
                'pages.settings.envelopes2.inactive-envelopes-tab.showEnvelope',
            ),
        }),
        [t, at, start, end],
    );

    const today = dayjs().tz().startOf('day');

    const form = useForm<FormShowEnvelope>({
        mode: 'onChange',
        shouldFocusError: true,
        resolver: yupResolver(
            yup.object<Record<keyof FormShowEnvelope, yup.AnySchema>>().shape(
                {
                    isAlways: yup.mixed<IsAlwaysValue>(),
                    startDate: yup
                        .date()
                        .nullable()
                        .typeError(copy.invalidDate)
                        .when('isAlways', {
                            is: isAlwaysValues.TIMED,
                            then: (schema) =>
                                schema
                                    .required(copy.requireStartDate)
                                    .test(
                                        'min',
                                        copy.startDateAfterToday,
                                        (value) =>
                                            value !== null &&
                                            dayjs(value).isSameOrAfter(today),
                                    )
                                    .when('endDate', (endDate, schema) =>
                                        endDate !== null &&
                                        dayjs(endDate).isValid()
                                            ? schema.test(
                                                  'end-date',
                                                  copy.startDateBeforeEndDate,
                                                  (startDate) =>
                                                      dayjs(
                                                          endDate,
                                                      ).isSameOrAfter(
                                                          dayjs(startDate),
                                                      ),
                                              )
                                            : schema,
                                    ),
                        }),
                    endDate: yup
                        .date()
                        .nullable()
                        .typeError(copy.invalidDate)
                        .when('isAlways', {
                            is: isAlwaysValues.TIMED,
                            then: (schema) =>
                                schema
                                    .required(copy.requireEndDate)
                                    .when('startDate', (startDate, schema) =>
                                        startDate !== null &&
                                        dayjs(startDate).isValid()
                                            ? schema.test(
                                                  'start-date',
                                                  copy.endDateAfterStartDate,
                                                  (endDate) =>
                                                      dayjs(
                                                          endDate,
                                                      ).isSameOrAfter(
                                                          dayjs(startDate),
                                                      ),
                                              )
                                            : schema,
                                    ),
                        }),
                },
                [['startDate', 'endDate']],
            ),
        ),
    });

    const { watch, clearErrors, setValue } = form;
    const isAlwaysValue = watch(['isAlways']).isAlways;
    const { startDate, endDate } = watch(['startDate', 'endDate']);
    const minStartDate = startDate
        ? dayjs.min([dayjs(startDate), today])
        : today;
    const maxStartDate =
        endDate && dayjs(endDate).isValid()
            ? dayjs(endDate).subtract(1, 'day')
            : undefined;
    const minEndDate = startDate ? startDate : today;

    useEffect(() => {
        setValue('startDate', null);
        setValue('endDate', null);
        //run only once
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setValue('isAlways', alwaysOn);
        //listen only to alwaysOn
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alwaysOn]);

    useEffect(() => {
        if (isAlwaysValue === 'Always') {
            clearErrors(['startDate', 'endDate']);
        }
    }, [isAlwaysValue, clearErrors]);

    const handleFormSubmit = (formValues: FormShowEnvelope) => {
        const { isAlways, startDate, endDate } = formValues;
        showEnvelope(
            envelopeId,
            isDefault,
            isAlways === 'Always',
            isAlways === 'Timed' && startDate
                ? toISODate(startDate)
                : undefined,
            isAlways === 'Timed' && endDate ? toISODate(endDate) : undefined,
        );
    };

    useEffect(() => {
        if (state === 'REQUEST_SUCCESS') {
            onCloseModal();
        }
    }, [state, onCloseModal]);

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleFormSubmit)}>
                <div className={modalTitle}>
                    <GivelifyLabel fontWeight={800} text={name} />
                    {!isAlways && (
                        <GivelifyLabel
                            text={copy.previousShow}
                            variant="caption2"
                        />
                    )}
                </div>
                <div className={switchImage}>
                    <GivelifyIcon size={64} variant="inactive-envelope" />
                    <GivelifyIcon
                        className={switchArrowImg}
                        variant="switch-arrow"
                    />
                    <GivelifyIcon size={64} variant="active-envelope" />
                </div>
                <div className={switchContent}>
                    <GivelifyLabel
                        className={switchTitle}
                        text={copy.askToShow}
                        variant="heading3"
                    />
                    <GivelifyLabel
                        className={mobileSmallTitle}
                        color={GivelifyColorPalette.navigationTextDefault}
                        text={copy.askDesc}
                    />
                    <GivelifyLabel
                        className={mobileSmallTitle}
                        color={GivelifyColorPalette.navigationTextDefault}
                        text={copy.askSelect}
                    />
                </div>
                {!isDefault && (
                    <div className={editorContainer}>
                        <GivelifyFormRadio<FormShowEnvelope>
                            className={alwaysRadio}
                            id="date-mode-always"
                            label={copy.always}
                            name="isAlways"
                            value={isAlwaysValues.ALWAYS}
                        />
                        <div className={specificDate}>
                            <GivelifyFormRadio<FormShowEnvelope>
                                className={alwaysRadio}
                                id="date-mode-custom"
                                label={copy.custom}
                                name="isAlways"
                                value={isAlwaysValues.TIMED}
                            />
                            <div
                                className={
                                    isAlwaysValue === 'Always' && !isMobile
                                        ? hidden
                                        : selectDate
                                }
                            >
                                <div className={selectDateItem}>
                                    <div className={dateFieldLabelWrapper}>
                                        <GivelifyLabel
                                            className={mobileDate}
                                            marginRight={8}
                                            text={copy.from}
                                            variant="body1"
                                        />
                                    </div>
                                    <GivelifyFormDatePicker<FormShowEnvelope>
                                        ariaLabel="from date picker"
                                        disabled={
                                            isAlwaysValue === 'Always' ||
                                            inProgress
                                        }
                                        fullwidth={isMobile}
                                        helperText="MM/DD/YYYY"
                                        id="from-date"
                                        label={copy.startDate}
                                        maxDate={maxStartDate}
                                        minDate={minStartDate}
                                        name="startDate"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </div>
                                <div className={selectDateItem}>
                                    <div className={dateFieldLabelWrapper}>
                                        <GivelifyLabel
                                            className={mobileDate}
                                            marginLeft={8}
                                            marginRight={8}
                                            text={copy.to}
                                            variant="body1"
                                        />
                                    </div>
                                    <GivelifyFormDatePicker<FormShowEnvelope>
                                        ariaLabel="to date picker"
                                        disabled={
                                            isAlwaysValue === 'Always' ||
                                            inProgress
                                        }
                                        fullwidth={isMobile}
                                        helperText="MM/DD/YYYY"
                                        id="to-date"
                                        label={copy.endDate}
                                        minDate={minEndDate}
                                        name="endDate"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className={switchBottom}>
                    <GivelifyButton
                        className={cancelBtn}
                        disabled={inProgress}
                        marginRight={16}
                        onClick={onCloseModal}
                        size="large"
                        text={copy.cancel}
                        variant="secondary"
                        width={268}
                    />
                    <GivelifyButton
                        className={hideEnvelopeBtn}
                        disabled={inProgress}
                        isLoading={inProgress}
                        size="large"
                        text={copy.showEnvelope}
                        type="submit"
                        variant="primary"
                        width={268}
                    />
                </div>
            </form>
        </FormProvider>
    );
};

export default ShowEnvelopeModalContent;
