import { EnabledFeature } from '@givelify/api';

export enum Features {
    OVERVIEW = 'Overview',
    DONATIONS = 'Donations',
    DONATIONS_ACTIVITY = 'Donations - Donations Activity',
    DONATIONS_BANK_DEPOSITS = 'Donations - Bank Deposits',
    DONATIONS_REFUNDS = 'Donations - Refunds',
    NEW_DONATION_PAGE = 'New Donations Activity Page',
    DONORS = 'Donors',
    FUNDRAISING_TOOLS = 'Fundraising Tools',
    FUNDRAISING_TOOLS_ONLINE_GIVING = 'Fundraising Tools - Online Giving',
    FUNDRAISING_TOOLS_SM_GIVING = 'Fundraising Tools - Social Media Giving',
    FUNDRAISING_TOOLS_SNAP_GIVE = 'Fundraising Tools - Snap to Give',
    FUNDRAISING_TOOLS_GIVELITHON = 'Fundraising Tools - Givelithon',
    SOCIAL_MEDIA_FACEBOOK = 'Fundraising Tools - Social Media Giving - Facebook',
    SOCIAL_MEDIA_TWITTER = 'Fundraising Tools - Social Media Giving - twitter',
    SOCIAL_MEDIA_INSTAGRAM = 'Fundraising Tools - Social Media Giving - instagram',
    SOCIAL_MEDIA_YOUTUBE = 'Fundraising Tools - Social Media Giving - youtube',
    SOCIAL_MEDIA_SNAPCHAT = 'Fundraising Tools - Social Media Giving - snapchat',
    SOCIAL_MEDIA_TIKTOK = 'Fundraising Tools - Social Media Giving --tiktok',
    SOCIAL_MEDIA_LINKEDIN = 'Fundraising Tools - Social Media Giving - linkedin',
    INTEGRATIONS = 'Integrations',
    INTEGRATION_F1 = 'Integration F1',
    INTEGRATION_F1_GO = 'Enable F1Go integration',
    INTEGRATION_CCB = 'Integration CCB',
    INTEGRATION_ACS_REALM = 'Integration ACS Realm',
    INTEGRATION_SCHEDULING = 'Integration - Scheduling',
    INTEGRATION_PC = 'Planning Center Integration',
    ENVELOPES_PUBLIC_DETAIL = 'Envelopes public/private details',
    REDIRECT_INTEGRATIONS = 'Integrations - Redirect',
    REPORTS = 'Reports',
    REPORTS_DONATIONS = 'Reports - Donations',
    REPORTS_BANK_DEPOSITS = 'Reports - Bank Deposits',
    REPORTS_RECONCILIATION = 'Reports - Reconciliation',
    REPORTS_HISTORY = 'Reports - History',
    REPORTS_DONORS = 'Reports - Donors',
    REPORTS_IMPORT_FRIENDLY = 'Reports - Import Friendly',
    REPORTS_QUICKBOOKS_ENVELOPE = 'Reports - Quickbooks Envelope',
    REPORTS_QUICKBOOKS_DONOR = 'Reports - Quickbooks Donor',
    REPORTS_DISBURSEMENTS = 'Reports - Disbursements',
    SETTINGS = 'Settings',
    LEGACY_REPORTS_DASHBOARD = 'Reports - Legacy Reports Dashboard',
    SETTINGS_ALL = 'Settings - All',
    SETTINGS_APP_PROFILE = 'Settings - App Profile',
    SETTINGS_BANK_INFO = 'Settings - Bank Info',
    SETTINGS_CAMPUSES = 'Settings - Campuses',
    SETTINGS_ENVELOPES = 'Settings - Envelopes',
    SETTINGS_MISSION = 'Settings - Mission Statement',
    SETTINGS_NEED_HELP = 'Settings - Need Help',
    SETTINGS_ORGANIZATION_INFO = 'Settings - Organization Info',
    SETTINGS_QUICK_GIVE = 'Settings - Quick Give',
    SETTINGS_USERS = 'Settings - Users',
    ONBOARDING = 'DoneeShowOnboarding',
    SHOW_YODLEE = 'Show Yodlee',
    FULL_STORY = 'Full Story',
    OVERVIEW_VIDEO_CARDS = 'Overview - Video Cards',
    NEW_ONBOARDING = 'Onboarding - New Experience',
    OVERVIEW_QUICK_POLL = 'Overview - Quick Poll',
    OVERVIEW_HIDE_OLD_DASHBOARD_LINK = 'Overview - Hide Old Dashboard Link',
    CAPTIVE_PORTAL = 'Onboarding - Captive Portal',
    TIMEZONES = 'Timezones',
    GIVING_TUESDAY_2023 = 'Giving Tuesday 2023',
    ONBOARDING_INVITE_OFFICIAL = 'New Invite Official flow',
    SCHEDULE_CALL_WITH_SUPPORT = 'Schedule call with support',
    PRIMARY_REP_ESCALATED_NOTIFICATION = 'New Primary Representative Page Escalated Notification',
    PRIMARY_REP_SEVERE_NOTIFICATION = 'New Primary Representative Page Severe Notification',
    PRIMARY_REP_JAN_1ST_NOTIFICATION = 'New Primary Representative Page January 1st Notification',
    NEW_DONOR_DETAILS_PAGE = 'Enable New Donor Details Page',
}

export const isFeatureEnabled = (
    availableFeatures: EnabledFeature[],
    featureLabel: string,
    defaultValue = true,
): boolean => {
    // by default, the other features are turned off
    if (availableFeatures.length === 0) {
        return false;
    }

    const selectedFeatures = availableFeatures.filter(
        (feature: EnabledFeature): boolean => {
            return feature.label === featureLabel;
        },
    );

    if (selectedFeatures.length === 1) {
        return selectedFeatures[0].isEnabled;
    }

    // if not found from the list, enable it
    return defaultValue;
};
