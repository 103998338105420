import React from 'react';
import { BasicPageWrapper, GivelifyLabel, GivelifyLink } from '@givelify/ui';
import { Stack, Grid } from '@mui/material';
import SocialMediaCard from '../../components/SocialMediaCard';
import Config from '../../config';
import { useConnectorState } from '../../provider/Connector';
import { SocialMediaName } from '../../types';
import { SOCIAL_GIVING_LOADED } from '../../utils/cleverTapEvents';
import { pushEvent } from '../../utils/cleverTapUtils';
import { getSocialMediaCards } from './const';

export const SocialMediaGiving: React.FCC = () => {
    const {
        routePaths,
        routeUrls,
        socialMediaFeatureFlag: featureFlag,
    } = useConnectorState();
    const socialMediaCards = getSocialMediaCards(routePaths, routeUrls);

    React.useEffect(() => {
        pushEvent(SOCIAL_GIVING_LOADED);
    }, []);

    return (
        <BasicPageWrapper
            showDivider
            contentWrapper="simple"
            title="Social Media Giving"
        >
            <GivelifyLabel marginBottom={3} variant="body1">
                Social media isn’t just for cat pictures anymore. By being
                present where your donors are, you can increase awareness of
                your mission. By including your Givelify social giving link,
                you’ll also increase your donations. Choose a service to find
                out how.
            </GivelifyLabel>
            <Grid container spacing={3}>
                {Object.entries(socialMediaCards)
                    .filter(([name]) => featureFlag[name as SocialMediaName])
                    .map(([name, item]) => (
                        <Grid
                            key={name}
                            item
                            lg={3}
                            marginBottom={1}
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <SocialMediaCard
                                actionUrl={item.url}
                                assetName={name as SocialMediaName}
                                {...item}
                            />
                        </Grid>
                    ))}
            </Grid>
            <Stack marginBottom={6} marginTop={8} textAlign="center">
                <GivelifyLink
                    href={Config.supportUrl}
                    rel="noreferrer"
                    target="_blank"
                    text="Need help? Contact support"
                />
            </Stack>
        </BasicPageWrapper>
    );
};
