import React, { useState, useMemo, useEffect } from 'react';
import { ApiHandler, ResetPasswordRequest } from '@givelify/api';
import { GivelifyFormTextField } from '@givelify/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Link } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { useAppDispatch } from 'store';
import * as Yup from 'yup';
import { getEmail } from '../../../store/user/thunks';
import { SET_EMAIL } from '../../../store/user/types';
import GivelifyModal from '../../../theme/components/GivelifyModal';
import Space from '../../Space';
import RecoverPassword from '../recoverPassword/RecoverPassword';
import {
    EmailWrapper,
    Form,
    SubmitButton,
    SubmitButtonWrapper,
    Title,
} from './useStyles';

const ResetPasswordForm: React.FCC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [formError, setFormError] = useState<string>('');
    const [send, setSent] = useState<boolean>(false);
    const navigate = useNavigate();
    const [open, setOpen] = useState<boolean>(false);
    const { PATH } = useGasRouterContext();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSignIn = () => {
        setOpen(false);
        navigate(PATH.DONEE.LOGIN);
    };

    const ResetPasswordFormDataText = useMemo(
        () => ({
            text: {
                required_password: t('reset_password.text.required_password'),
                required_confirm_password: t(
                    'reset_password.text.required_confirm_password',
                ),
                success: t('reset_password.text.success'),
                login: t('reset_password.text.login'),
                yourEmail: t('reset_password.text.your_email'),
                notYou: t('reset_password.text.not_you'),
                matched_password: t('reset_password.text.matched_password'),
                short_password: t('reset_password.text.short_password'),
                error_password: t('reset_password.text.error_password'),
                page: t('reset_password.text.page'),
            },
            title: {
                password: t('reset_password.title.password'),
                password_holder: t('reset_password.title.password_holder'),
                confirm_password: t('reset_password.title.confirm_password'),
                confirm_password_holder: t(
                    'reset_password.title.confirm_password_holder',
                ),
                reset_password: t('reset_password.title.reset_password'),
            },
        }),
        [t],
    );

    const form = useForm<ResetPasswordRequest>({
        mode: 'onBlur',
        resolver: yupResolver(
            Yup.object({
                password: Yup.string()
                    .required(ResetPasswordFormDataText.text.required_password)
                    .min(6, ResetPasswordFormDataText.text.short_password)
                    .matches(/^(?=.*[A-Z])(?=.*\d).*$/, {
                        message: ResetPasswordFormDataText.text.error_password,
                        excludeEmptyString: true,
                    }),
                passwordConfirmation: Yup.string()
                    .required(
                        ResetPasswordFormDataText.text
                            .required_confirm_password,
                    )
                    .oneOf(
                        [Yup.ref('password'), null],
                        ResetPasswordFormDataText.text.matched_password,
                    ),
                token: Yup.string().optional().nullable(),
            }) as Yup.SchemaOf<ResetPasswordRequest>,
        ),
        defaultValues: {
            token: '',
            password: '',
            passwordConfirmation: '',
        },
    });

    const submitDisabled = useMemo(
        () =>
            !(
                form.getValues().password &&
                form.getValues().passwordConfirmation
            ),
        [form],
    );

    const handleSubmit = async (value: ResetPasswordRequest) => {
        value.token = window.location.search.split('=').pop();
        const request = await ApiHandler.instance.auth.resetPassword(value);
        if (request.success) {
            setSent(true);
            setFormError('');
        } else {
            setFormError(request.error.message);
        }
    };

    useEffect(() => {
        const token = window.location.search.split('=').pop();

        if (!token) {
            navigate(PATH.DONEE.LOGIN);
        } else {
            dispatch(getEmail(token));
        }
        // run only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const userEmail = localStorage.getItem(SET_EMAIL)
        ? localStorage.getItem(SET_EMAIL)
        : '';

    return (
        <FormProvider {...form}>
            <Form onSubmit={form.handleSubmit(handleSubmit)}>
                <Title variant="h1">
                    {ResetPasswordFormDataText.text.page}
                </Title>
                {!send && (
                    <>
                        <Typography color="error">{formError}</Typography>
                        <EmailWrapper>
                            <Typography
                                data-testid="emailWrapper"
                                variant="subtitle1"
                            >
                                {ResetPasswordFormDataText.text.yourEmail}
                                {': '}
                                <Link
                                    data-testid="mailto"
                                    href={'mailto:' + userEmail}
                                >
                                    {userEmail}
                                </Link>{' '}
                            </Typography>
                            <Typography
                                data-testid="notYourEmail"
                                variant="subtitle1"
                            >
                                <Link onClick={handleOpen}>
                                    ( {ResetPasswordFormDataText.text.notYou} )
                                </Link>
                            </Typography>
                        </EmailWrapper>
                        <GivelifyFormTextField<ResetPasswordRequest>
                            fullWidth
                            passwordToggle
                            data-testid="passwordWrapper"
                            id="password"
                            inputProps={{
                                'aria-label': 'Password input',
                            }}
                            label={ResetPasswordFormDataText.title.password}
                            name="password"
                            placeholder={
                                ResetPasswordFormDataText.title.password_holder
                            }
                            type="password"
                        />
                        <Space gap={4} />
                        <GivelifyFormTextField<ResetPasswordRequest>
                            fullWidth
                            passwordToggle
                            data-testid='passwordConfirmWrapper'
                            id="password_confirmation"
                            inputProps={{
                                'aria-label': 'Password input',
                            }}
                            label={
                                ResetPasswordFormDataText.title.confirm_password
                            }
                            name="passwordConfirmation"
                            placeholder={
                                ResetPasswordFormDataText.title
                                    .confirm_password_holder
                            }
                            type="password"
                        />
                        <Space gap={4} />
                        <SubmitButtonWrapper>
                            <SubmitButton
                                disabled={submitDisabled}
                                name="submit"
                                onClick={form.handleSubmit(handleSubmit)}
                                size="large"
                                text={
                                    ResetPasswordFormDataText.title
                                        .reset_password
                                }
                                variant="primary"
                            />
                        </SubmitButtonWrapper>
                    </>
                )}
                {send && (
                    <Typography variant="h4">
                        {ResetPasswordFormDataText.text.success}
                        <Link onClick={() => navigate(PATH.DONEE.LOGIN)}>
                            {ResetPasswordFormDataText.text.login}
                        </Link>
                    </Typography>
                )}
                <GivelifyModal
                    handleClose={handleClose}
                    isOpen={open}
                    label="Reset Password"
                >
                    <RecoverPassword handleClose={handleSignIn} />
                </GivelifyModal>
            </Form>
        </FormProvider>
    );
};

export default ResetPasswordForm;
