import React from 'react';
import { Donee, User } from '@givelify/api';
import { useCaptivePortalContext } from '@givelify/onboarding';
import {
    DesignTokens,
    GivelifyLabel,
    GivelifyNotificationProps,
} from '@givelify/ui';
import {
    LINK_IDENTIFIER,
    PAGE_NAME,
    useTrackingContext,
} from '@givelify/utils';
import { SnackbarCloseReason } from '@mui/material';
import dayjs from 'dayjs';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Location } from 'react-router-dom';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { PATH } from 'router/routes';
import { AppState } from 'store';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import RoleTypes from '../../../constants/roleTypes';
import { PrimaryRepJan1stContent } from '../customNotifications';
import { NotificationContext } from '../notificationContext';

const ADD_PRIMARY_REP_DEADLINE = '2024-10-01';

enum NotificationType {
    PRIMARY_SEVERE = 'PRIMARY_SEVERE',
    PRIMARY_ESCALATED = 'PRIMARY_ESCALATED',
    PRIMARY_JAN_1ST = 'PRIMARY_JAN_1ST',
    NONE = 'NONE',
}

export const NotificationProvider: React.FCC = ({ children }) => {
    const { trackEventRaw } = useTrackingContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { PATH: GAS_ROUTER_PATH } = useGasRouterContext();
    const { onboardingNotification, showCaptivePortal } =
        useCaptivePortalContext();
    const [notification, setNotification] =
        React.useState<GivelifyNotificationProps | null>(null);
    const notificationRef = React.useRef<HTMLDivElement>(null);
    const [notificationHeight, setNotificationHeight] = React.useState(0);
    const {
        newEscalatedPrimaryRepVerificationEnabled,
        newSeverePrimaryRepVerificationEnabled,
        newJan1stPrimaryRepVerificationEnabled,
        donee,
        mainDoneeId,
        user,
    } = useSelector((state: AppState) => ({
        newEscalatedPrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_ESCALATED_NOTIFICATION,
            false,
        ),
        newSeverePrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_SEVERE_NOTIFICATION,
            false,
        ),
        newJan1stPrimaryRepVerificationEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.PRIMARY_REP_JAN_1ST_NOTIFICATION,
            false,
        ),
        newDonorDetailsPageEnabled: isFeatureEnabled(
            state.System.enabledFeatures,
            Features.NEW_DONOR_DETAILS_PAGE,
            false,
        ),
        donee: state.Donee.donee,
        mainDoneeId: state.Donee.campuses ? state.Donee.campuses[0].id : null,
        user: state.User.user,
    }));

    const openNotification = React.useCallback(
        (content: GivelifyNotificationProps) => {
            setNotification(content);
        },
        [],
    );
    const closeNotification = React.useCallback(
        (_, reason: SnackbarCloseReason) => {
            if (reason !== 'clickaway') {
                setNotification(null);
            }
        },
        [],
    );
    React.useEffect(() => {
        if (!showCaptivePortal && onboardingNotification) {
            openNotification(onboardingNotification);
        } else {
            openNotification(null);
        }
    }, [showCaptivePortal, openNotification, onboardingNotification]);
    const determineNotificationType = (
        newSevereEnabled: boolean,
        newEscalatedEnabled: boolean,
        newJan1stEnabled: boolean,
        user: User,
        donee: Donee,
        mainDoneeId: number | null,
        location: Location,
        excludePath: string,
    ): NotificationType => {
        if (!newSevereEnabled && !newEscalatedEnabled) {
            return NotificationType.NONE;
        }
        const isAdmin = user.role === RoleTypes.ADMIN;
        const isBasicOrFinancial =
            user.role === RoleTypes.BASIC || user.role === RoleTypes.FINANCIAL;
        const validUser =
            mainDoneeId !== null &&
            donee.id === mainDoneeId &&
            (isAdmin || isBasicOrFinancial);
        const hasMid = donee.onboarding.hasMid;
        const isBahamas = donee.country === 'BHS';
        const hasPrimaryRep =
            donee.onboarding.primaryRepresentative &&
            donee.onboarding.primaryRepresentative.firstName;
        if (
            validUser &&
            hasMid &&
            !hasPrimaryRep &&
            !isBahamas &&
            (isAdmin ||
                location.pathname !== excludePath ||
                !location.search.includes('rep'))
        ) {
            if (newJan1stEnabled) {
                return NotificationType.PRIMARY_JAN_1ST;
            }

            return newSevereEnabled
                ? NotificationType.PRIMARY_SEVERE
                : NotificationType.PRIMARY_ESCALATED;
        }
        return NotificationType.NONE;
    };
    const getPrimaryRepVerificationSevere = (
        userRole: string,
        leftDays: number,
        isAfterDeadline: boolean,
        t: TFunction,
        enabledLocationAdmin: boolean,
        enabledLocationNonAdmin: boolean,
        isAdmin: boolean,
        trackEventRaw: (event: string) => void,
        navigate: (path: string) => void,
    ): GivelifyNotificationProps => {
        const adminPrefix = userRole === RoleTypes.ADMIN ? 'admin' : 'basic';
        const severityPrefix = isAfterDeadline ? 'due' : 'severe';
        const actionButtonProps = !(
            (!enabledLocationNonAdmin && !isAdmin) ||
            (!enabledLocationAdmin && isAdmin)
        )
            ? {
                  text: t(
                      `notifications.addPrimaryRep.common.${adminPrefix}.buttonText`,
                  ),
                  onClick: () => {
                      trackEventRaw(
                          `Click [${
                              isAdmin
                                  ? PAGE_NAME.PrimaryRepresentativeNotification
                                  : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                          }] Box <${
                              isAdmin ? 'Get Started' : 'Get Started Non Admin'
                          }>_${LINK_IDENTIFIER}`,
                      );
                      navigate(
                          isAdmin
                              ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO('rep')
                              : GAS_ROUTER_PATH.SETTINGS.USERS(),
                      );
                  },
              }
            : undefined;
        let about = 'Primary rep severe notification with action button';
        if (actionButtonProps) {
            about = 'Primary rep severe notification';
        }
        return {
            id: 'primaryRepVerificationSevere',
            text: (
                <GivelifyLabel variant="body2B">
                    <GivelifyLabel
                        color={
                            severityPrefix === 'severe'
                                ? DesignTokens.color.textErrorDefault
                                : undefined
                        }
                        variant="body2B"
                    >
                        {t(
                            `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title1`,
                            { DAYS: leftDays },
                        )}
                    </GivelifyLabel>
                    {severityPrefix === 'severe'
                        ? t(
                              `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.title2`,
                          )
                        : null}
                </GivelifyLabel>
            ),
            description: t(
                `notifications.addPrimaryRep.${severityPrefix}.${adminPrefix}.description`,
            ),
            variant: 'error',
            actionButtonProps,
            about,
        };
    };
    const getPrimaryRepVerificationEscalated = (
        userRole: string,
        t: TFunction,
        enabledLocationAdmin: boolean,
        enabledLocationNonAdmin: boolean,
        isAdmin: boolean,
        trackEventRaw: (event: string) => void,
        navigate: (path: string) => void,
    ): GivelifyNotificationProps => {
        const adminPrefix = userRole === RoleTypes.ADMIN ? 'admin' : 'basic';
        const actionButtonProps = !(
            (!enabledLocationNonAdmin && !isAdmin) ||
            (!enabledLocationAdmin && isAdmin)
        )
            ? {
                  text: t(
                      `notifications.addPrimaryRep.common.${adminPrefix}.buttonText`,
                  ),
                  onClick: () => {
                      trackEventRaw(
                          `Click [${
                              isAdmin
                                  ? PAGE_NAME.PrimaryRepresentativeNotification
                                  : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                          }] Box <${
                              isAdmin ? 'Get Started' : 'Get Started Non Admin'
                          }>`,
                      );
                      navigate(
                          isAdmin
                              ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO('rep')
                              : GAS_ROUTER_PATH.SETTINGS.USERS(),
                      );
                  },
              }
            : undefined;
        let about = 'Primary rep escaleted notification with action button';
        if (actionButtonProps) {
            about = 'Primary rep escaleted notification';
        }
        return {
            id: 'primaryRepVerificationEscalated',
            text: t(`notifications.addPrimaryRep.warning.${adminPrefix}.title`),
            description: t(
                `notifications.addPrimaryRep.warning.${adminPrefix}.description`,
            ),
            variant: 'warning',
            actionButtonProps,
            about,
        };
    };
    const getPrimaryRepVerificationJan1st = (
        userRole: string,
        enabledLocationAdmin: boolean,
        enabledLocationNonAdmin: boolean,
        isAdmin: boolean,
        trackEventRaw: (event: string) => void,
        navigate: (path: string) => void,
    ): GivelifyNotificationProps => {
        const adminPrefix = userRole === RoleTypes.ADMIN ? 'admin' : 'basic';
        const actionButtonProps = !(
            (!enabledLocationNonAdmin && !isAdmin) ||
            (!enabledLocationAdmin && isAdmin)
        )
            ? {
                  onClick: () => {
                      trackEventRaw(
                          `Click [${
                              isAdmin
                                  ? PAGE_NAME.PrimaryRepresentativeNotification
                                  : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                          }] Box <${
                              isAdmin ? 'Get Started' : 'Get Started Non Admin'
                          }>_${LINK_IDENTIFIER}`,
                      );
                      navigate(
                          isAdmin
                              ? GAS_ROUTER_PATH.SETTINGS.BANK_INFO('rep')
                              : GAS_ROUTER_PATH.SETTINGS.USERS(),
                      );
                  },
              }
            : undefined;
        let about = 'Primary rep Jan 1st notification with action button';
        if (actionButtonProps) {
            about = 'Primary rep Jan 1st notification';
        }
        return {
            id: 'primaryRepVerificationJan1st',
            customMessage: (
                <PrimaryRepJan1stContent
                    adminPrefix={adminPrefix}
                    onActionClick={actionButtonProps?.onClick}
                />
            ),
            variant: 'warning',
            about,
            meta: 'content',
        };
    };
    // Escalated Primary Rep Verification Notification
    React.useEffect(() => {
        const notificationType = determineNotificationType(
            newSeverePrimaryRepVerificationEnabled,
            newEscalatedPrimaryRepVerificationEnabled,
            newJan1stPrimaryRepVerificationEnabled,
            user,
            donee,
            mainDoneeId,
            location,
            PATH.SETTINGS.BANK_INFO,
        );
        let newNotification: GivelifyNotificationProps | null = null;
        const enabledLocationAdmin =
            location.pathname !== PATH.SETTINGS.BANK_INFO ||
            !location.search.includes('rep');
        const enabledLocationNonAdmin =
            location.pathname !== PATH.SETTINGS.USERS;
        switch (notificationType) {
            case NotificationType.PRIMARY_SEVERE: {
                const leftDays = dayjs
                    .tz(ADD_PRIMARY_REP_DEADLINE)
                    .diff(dayjs().tz(), 'days');
                const isAfterOctober1 = dayjs
                    .tz()
                    .isAfter(dayjs.tz(ADD_PRIMARY_REP_DEADLINE));

                newNotification = getPrimaryRepVerificationSevere(
                    user.role,
                    leftDays,
                    isAfterOctober1,
                    t,
                    enabledLocationAdmin,
                    enabledLocationNonAdmin,
                    user.role === RoleTypes.ADMIN,
                    trackEventRaw,
                    navigate,
                );
                break;
            }
            case NotificationType.PRIMARY_ESCALATED:
                newNotification = getPrimaryRepVerificationEscalated(
                    user.role,
                    t,
                    enabledLocationAdmin,
                    enabledLocationNonAdmin,
                    user.role === RoleTypes.ADMIN,
                    trackEventRaw,
                    navigate,
                );
                break;
            case NotificationType.PRIMARY_JAN_1ST: {
                newNotification = getPrimaryRepVerificationJan1st(
                    user.role,
                    enabledLocationAdmin,
                    enabledLocationNonAdmin,
                    user.role === RoleTypes.ADMIN,
                    trackEventRaw,
                    navigate,
                );
                break;
            }

            case NotificationType.NONE:
                if (
                    notification?.id === 'primaryRepVerificationSevere' ||
                    notification?.id === 'primaryRepVerificationEscalated' ||
                    notification?.id === 'primaryRepVerificationJan1st'
                ) {
                    setNotification(null);
                }
                return;
        }
        if (newNotification) {
            if (
                notification &&
                notification.id === newNotification.id &&
                notification.about === newNotification.about
            ) {
                return;
            }
            trackEventRaw(
                `View [${
                    user.role === RoleTypes.ADMIN
                        ? PAGE_NAME.PrimaryRepresentativeNotification
                        : PAGE_NAME.PrimaryRepresentativeNonAdminNotification
                }] Box`,
            );
            setNotification(newNotification);
        }
        // eslint-disable-next-line
    }, [
        donee,
        location.pathname,
        location.search,
        newEscalatedPrimaryRepVerificationEnabled,
        newSeverePrimaryRepVerificationEnabled,
    ]);
    React.useEffect(() => {
        if (!notification) {
            setNotificationHeight(0);
            return;
        }
        const updateHeight = () => {
            if (notificationRef.current) {
                setNotificationHeight(notificationRef.current.offsetHeight);
            }
        };
        updateHeight();
        window.addEventListener('resize', updateHeight);
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, [notification]);
    return (
        <NotificationContext.Provider
            value={{
                showNotification: openNotification,
                closeNotification,
                notification,
                notificationHeight,
                notificationRef,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
};
