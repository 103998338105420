import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ApiHandler } from '@givelify/api';
import {
    initialIntegrationPaginatedResponse,
    IntegrationPaginatedResponse,
} from '@givelify/givelify-ui';
import {
    MODAL_NAME,
    requestInit,
    useApiRequest,
    useTrackingContext,
} from '@givelify/utils';
import { Envelope } from 'api/models';
import {
    UpdateEnvelopeRequest,
    UpdateEnvelopeResponse,
    updateEnvelope as updateEnvelopeRequest,
    createEnvelope as createEnvelopeRequest,
} from 'api/requests';
import { CreateEnvelopesResponse } from 'api/services/responses/envelopes/CreateEnvelopesResponse';
import permissionTypes from 'constants/permissionTypes';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { PATH } from 'router/routes';
import { AppState, useAppDispatch } from 'store';
import { setNotificationFlag } from 'store/notifications/actions';
import { updateNotification } from 'store/notifications/thunks';
import permissionsByPath from 'utils/permissionsByPath';
import { ToEnvelope as CreateEnvelopeResponseToEnvelope } from '../models/CreateEnvelopeResponse';
import { ToEnvelope as UpdateEnvelopeResponseToEnvelope } from '../models/UpdateEnvelopesResponse';
import { EnvelopesContext } from './EnvelopesContext';
import {
    getActiveEnvelopes,
    getInactiveEnvelopes,
    hideEnvelope as hideEnvelopeRequest,
    showEnvelope as showEnvelopeRequest,
    deleteEnvelope as deleteEnvelopeRequest,
    updatePriorities as updatePrioritiesRequest,
} from './request';

export const useEnvelopesContext = () => useContext(EnvelopesContext);

export const EnvelopesProvider: React.FCC = ({ children }) => {
    const { trackOpenCloseEvent } = useTrackingContext();
    const { doneeId, user, showNotification } = useSelector(
        (state: AppState) => ({
            doneeId: state.Donee.donee.id,
            user: state.User.user,
            showNotification: !state.Notifications.hideEnvelopesNotification,
        }),
    );

    const hasFullAccess =
        permissionsByPath[PATH.SETTINGS.ENVELOPES][user?.role] ===
        permissionTypes.FULL_ACCESS;

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (showNotification) {
            dispatch(updateNotification('hideEnvelopesNotification', true));
        }
        return () => {
            dispatch(setNotificationFlag('hideEnvelopesNotification', true));
        };
    }, [showNotification, dispatch]);

    const hideNotificationClick = () =>
        dispatch(setNotificationFlag('hideEnvelopesNotification', true));

    const [activeEnvelopes, setActiveEnvelopes] = useState<Envelope[]>([]);

    const [getActiveEnvelopesRequestState, makeGetActiveEnvelopesRequest] =
        useApiRequest<IntegrationPaginatedResponse<Envelope>>();

    const [inactiveEnvelopes, setInactiveEnvelopes] = useState<
        IntegrationPaginatedResponse<Envelope>
    >(initialIntegrationPaginatedResponse);

    const [getInactiveEnvelopesRequestState, makeGetInactiveEnvelopesRequest] =
        useApiRequest<IntegrationPaginatedResponse<Envelope>>();

    const [
        getInactiveEnvelopesRequestData,
        setGetInactiveEnvelopesRequestData,
    ] = useState<{
        doneeId: number;
        pageNumber: number;
    }>({
        doneeId,
        pageNumber: 1,
    });

    const [activeEnvelopesLoading, setActiveEnvelopesLoading] = useState(true);
    const [inactiveEnvelopesLoading, setInactiveEnvelopesLoading] =
        useState(true);

    useEffect(() => {
        setActiveEnvelopesLoading(true);
        makeGetActiveEnvelopesRequest(getActiveEnvelopes(doneeId));
    }, [makeGetActiveEnvelopesRequest, doneeId]);

    useEffect(() => {
        setInactiveEnvelopesLoading(true);
        makeGetInactiveEnvelopesRequest(
            getInactiveEnvelopes(
                getInactiveEnvelopesRequestData.doneeId,
                getInactiveEnvelopesRequestData.pageNumber,
            ),
        );
    }, [
        makeGetInactiveEnvelopesRequest,
        getInactiveEnvelopesRequestData.doneeId,
        getInactiveEnvelopesRequestData.pageNumber,
    ]);

    useEffect(() => {
        setGetInactiveEnvelopesRequestData({
            doneeId,
            pageNumber: 1,
        });
    }, [doneeId]);

    const loadNewInactivePage = () => {
        if (inactiveEnvelopesLoading) return;

        setInactiveEnvelopesLoading(true);
        setGetInactiveEnvelopesRequestData((prev) => ({
            ...prev,
            pageNumber: prev.pageNumber + 1,
        }));
    };

    useEffect(() => {
        if (getActiveEnvelopesRequestState.type !== 'REQUEST_SUCCESS') return;

        const envelopes = getActiveEnvelopesRequestState.response.data;
        setActiveEnvelopes(envelopes);

        setActiveEnvelopesLoading(false);
    }, [getActiveEnvelopesRequestState]);

    useEffect(() => {
        if (getInactiveEnvelopesRequestState.type !== 'REQUEST_SUCCESS') return;

        const newData: IntegrationPaginatedResponse<Envelope> = {
            data: getInactiveEnvelopesRequestState.response.data,
            meta: getInactiveEnvelopesRequestState.response.meta,
        };
        if (getInactiveEnvelopesRequestState.response.meta.currentPage === 1) {
            setInactiveEnvelopes(newData);
        } else {
            setInactiveEnvelopes((prevDataSet) => ({
                ...prevDataSet,
                data: [...prevDataSet.data, ...newData.data],
            }));
        }
        setInactiveEnvelopesLoading(false);
    }, [getInactiveEnvelopesRequestState, setInactiveEnvelopes]);

    const [
        hideEnvelopeRequestState,
        makeHideEnvelopeRequest,
        setHideEnvelopeRequestState,
    ] = useApiRequest<UpdateEnvelopeResponse>();

    const hideEnvelope = (envelopeId: number) =>
        makeHideEnvelopeRequest(hideEnvelopeRequest(doneeId, envelopeId));

    useEffect(() => {
        if (hideEnvelopeRequestState.type !== 'REQUEST_SUCCESS') return;

        setHideEnvelopeRequestState(requestInit());

        const hiddenEnvelopeId = hideEnvelopeRequestState.response.data.id;
        const currentHiddenEnvelope = activeEnvelopes.find(
            (e) => e.id === hiddenEnvelopeId,
        );
        const updatedHiddenEnvelope = UpdateEnvelopeResponseToEnvelope(
            currentHiddenEnvelope,
            hideEnvelopeRequestState.response,
        );
        setActiveEnvelopes((prev) =>
            prev.filter((e) => e.id !== hiddenEnvelopeId),
        );

        if (updatedHiddenEnvelope) {
            setInactiveEnvelopes((prev) => ({
                ...prev,
                data: [{ ...updatedHiddenEnvelope }, ...prev.data],
            }));
        }
    }, [
        hideEnvelopeRequestState,
        activeEnvelopes,
        setHideEnvelopeRequestState,
    ]);
    const queryClient = useQueryClient();
    const [
        showEnvelopeRequestState,
        makeShowEnvelopeRequest,
        setShowEnvelopeRequestState,
    ] = useApiRequest<UpdateEnvelopeResponse>();

    const showEnvelope = (
        envelopeId: number,
        isDefault: boolean,
        always: boolean,
        startDate?: string,
        endDate?: string,
    ) => {
        const currentMaxPriority = Math.max(
            ...activeEnvelopes.map((e) => e.priority),
        );
        const newMaxPriority = isFinite(currentMaxPriority)
            ? currentMaxPriority + 1
            : 0;

        makeShowEnvelopeRequest(
            showEnvelopeRequest(
                doneeId,
                envelopeId,
                isDefault,
                always ? 'Always' : 'Timed',
                startDate,
                endDate,
                newMaxPriority,
            ),
        );
    };

    useEffect(() => {
        if (showEnvelopeRequestState.type !== 'REQUEST_SUCCESS') return;

        setShowEnvelopeRequestState(requestInit());

        const shownEnvelopeId = showEnvelopeRequestState.response.data.id;
        const currentShownEnvelope = inactiveEnvelopes.data.find(
            (e) => e.id === shownEnvelopeId,
        );
        const updatedShownEnvelope = UpdateEnvelopeResponseToEnvelope(
            currentShownEnvelope,
            showEnvelopeRequestState.response,
        );

        setActiveEnvelopes((prev) => [...prev, updatedShownEnvelope]);
        setInactiveEnvelopes((prev) => ({
            ...prev,
            data: prev.data.filter((e) => e.id !== shownEnvelopeId),
        }));
        queryClient.invalidateQueries(
            ApiHandler.instance.envelopes.getEnvelopesData.name,
        );
    }, [
        showEnvelopeRequestState,
        inactiveEnvelopes.data,
        setShowEnvelopeRequestState,
        queryClient,
    ]);

    const [editTarget, setEditTarget] = useState<Envelope | undefined>();

    const createEnvelopeClick = () => {
        setEditTarget({
            id: null,
            doneeId: null,
            name: '',
            detail: '',
            externalId: '',
            offeringId: null,
            alwaysOn: 'Always',
            start: null,
            end: null,
            goal: null,
            amount: null,
            active: true,
            donationCount: 0,
            priority: null,
            isDetailPublic: false,
            isDefault: false,
        });
        trackOpenCloseEvent(true, MODAL_NAME.CreateEnvelope);
    };

    const editEnvelopeClick = (id: number) => {
        const targetEnvelope =
            activeEnvelopes.find((e) => e.id === id) ||
            inactiveEnvelopes.data.find((e) => e.id === id);
        if (targetEnvelope) {
            setEditTarget({ ...targetEnvelope });
            trackOpenCloseEvent(true, MODAL_NAME.EditEnvelope);
        }
    };

    const closeEditForm = useCallback(() => {
        if (editTarget?.id === null) {
            trackOpenCloseEvent(false, MODAL_NAME.CreateEnvelope);
        } else {
            trackOpenCloseEvent(false, MODAL_NAME.EditEnvelope);
        }
        setEditTarget(undefined);
    }, [editTarget, trackOpenCloseEvent]);

    const [
        createEnvelopeRequestState,
        makeCreateEnvelopeRequest,
        setCreateEnvelopeRequestState,
    ] = useApiRequest<CreateEnvelopesResponse>();

    const createEnvelope = async (data: UpdateEnvelopeRequest) => {
        const currentMaxPriority = Math.max(
            ...activeEnvelopes.map((e) => e.priority),
        );
        const newMaxPriority = isFinite(currentMaxPriority)
            ? currentMaxPriority + 1
            : 0;

        void makeCreateEnvelopeRequest(
            createEnvelopeRequest(doneeId, {
                ...data,
                priority: newMaxPriority,
            }),
        );
    };

    useEffect(() => {
        if (createEnvelopeRequestState.type !== 'REQUEST_SUCCESS') return;

        setCreateEnvelopeRequestState(requestInit());

        const newEnvelope = createEnvelopeRequestState.response;

        setActiveEnvelopes((prev) => [
            ...prev,
            CreateEnvelopeResponseToEnvelope(newEnvelope),
        ]);
        queryClient.invalidateQueries(
            ApiHandler.instance.envelopes.getEnvelopesData.name,
        );
    }, [
        createEnvelopeRequestState,
        queryClient,
        setCreateEnvelopeRequestState,
    ]);

    const [
        updateEnvelopeRequestState,
        makeUpdateEnvelopeRequest,
        setUpdateEnvelopeRequestState,
    ] = useApiRequest<UpdateEnvelopeResponse>();

    const updateEnvelope = async (data: UpdateEnvelopeRequest) => {
        void makeUpdateEnvelopeRequest(updateEnvelopeRequest(doneeId, data));
    };

    useEffect(() => {
        if (updateEnvelopeRequestState.type !== 'REQUEST_SUCCESS') return;

        setUpdateEnvelopeRequestState(requestInit());

        const updatedEnvelope = updateEnvelopeRequestState.response;
        const updatedEnvelopeId = updatedEnvelope.data.id;
        if (updatedEnvelope.data.active) {
            const currentEnvelope = activeEnvelopes.find(
                (e) => e.id === updatedEnvelopeId,
            );
            const newEnvelope = UpdateEnvelopeResponseToEnvelope(
                currentEnvelope,
                updatedEnvelope,
            );
            setActiveEnvelopes((prev) =>
                prev.map((e) => (e.id === updatedEnvelopeId ? newEnvelope : e)),
            );
        } else {
            const currentEnvelope = inactiveEnvelopes.data.find(
                (e) => e.id === updatedEnvelopeId,
            );
            const newEnvelope = UpdateEnvelopeResponseToEnvelope(
                currentEnvelope,
                updatedEnvelope,
            );
            setInactiveEnvelopes((prev) => ({
                ...prev,
                data: prev.data.map((e) =>
                    e.id === updatedEnvelopeId ? newEnvelope : e,
                ),
            }));
        }
        queryClient.invalidateQueries(
            ApiHandler.instance.envelopes.getEnvelopesData.name,
        );
    }, [
        updateEnvelopeRequestState,
        activeEnvelopes,
        inactiveEnvelopes.data,
        setUpdateEnvelopeRequestState,
        queryClient,
    ]);

    const [
        deleteEnvelopeRequestState,
        makeDeleteEnvelopeRequest,
        setDeleteEnvelopeRequestState,
    ] = useApiRequest();

    const deleteEnvelope = async (id: number) => {
        void makeDeleteEnvelopeRequest(deleteEnvelopeRequest(doneeId, id));
    };

    useEffect(() => {
        if (deleteEnvelopeRequestState.type !== 'REQUEST_SUCCESS') return;

        setDeleteEnvelopeRequestState(requestInit());

        const deletedEnvelopeId = editTarget?.id;
        const isActive = editTarget?.active;

        if (isActive) {
            setActiveEnvelopes((prev) =>
                prev.filter((e) => e.id !== deletedEnvelopeId),
            );
        } else {
            setInactiveEnvelopes((prev) => ({
                ...prev,
                data: prev.data.filter((e) => e.id !== deletedEnvelopeId),
            }));
        }
        queryClient.invalidateQueries(
            ApiHandler.instance.envelopes.getEnvelopesData.name,
        );
        closeEditForm();
    }, [
        closeEditForm,
        deleteEnvelopeRequestState,
        editTarget,
        queryClient,
        setDeleteEnvelopeRequestState,
    ]);

    const [
        updatePrioritiesRequestState,
        makeUpdatePrioritiesRequestState,
        setUpdatePrioritiesRequestState,
    ] = useApiRequest();

    const dndDisabled =
        !hasFullAccess || updatePrioritiesRequestState.type !== 'REQUEST_INIT';

    const [movedItemId, setMovedItemId] = useState<number>();
    const [movedItemFromTo, setMovedItemFromTo] = useState<{
        from: number;
        to: number;
    }>();

    const dragAndDrop = useCallback(
        (from: number, to: number) => {
            const movedItem = activeEnvelopes.find(
                (e, index) => index === from,
            );
            const restItems = activeEnvelopes.filter(
                (e, index) => index !== from,
            );

            setMovedItemId(movedItem.id);
            setMovedItemFromTo({
                from,
                to,
            });

            const reorderedItems = [
                ...restItems.slice(0, to),
                movedItem,
                ...restItems.slice(to),
            ];

            setActiveEnvelopes(reorderedItems);
            setShowCheckMark(false);

            makeUpdatePrioritiesRequestState(
                updatePrioritiesRequest(
                    doneeId,
                    reorderedItems.map((e, index) => ({
                        envelopeId: e.id,
                        priority: index,
                    })),
                ),
            );
        },
        [activeEnvelopes, doneeId, makeUpdatePrioritiesRequestState],
    );

    const rollbackDragAndDrop = useCallback(() => {
        const { from, to } = movedItemFromTo;

        const movedItem = activeEnvelopes.find((e, index) => index === from);
        const restItems = activeEnvelopes.filter((e, index) => index !== from);

        const reorderedItems = [
            ...restItems.slice(0, to),
            movedItem,
            ...restItems.slice(to),
        ];

        setActiveEnvelopes(reorderedItems);

        setUpdatePrioritiesRequestState(requestInit());
    }, [movedItemFromTo, activeEnvelopes, setUpdatePrioritiesRequestState]);

    const timerId = useRef(null);
    const [showCheckMark, setShowCheckMark] = useState(false);

    useEffect(() => {
        if (updatePrioritiesRequestState.type !== 'REQUEST_SUCCESS') return;

        setUpdatePrioritiesRequestState(requestInit());
        setShowCheckMark(true);
        timerId.current = setTimeout(() => {
            setShowCheckMark(false);
        }, 3000);
        queryClient.invalidateQueries(
            ApiHandler.instance.envelopes.getEnvelopesData.name,
        );
    }, [
        queryClient,
        setUpdatePrioritiesRequestState,
        updatePrioritiesRequestState.type,
    ]);

    return (
        <EnvelopesContext.Provider
            value={{
                hasFullAccess,

                activeEnvelopes,
                getActiveEnvelopesRequestState:
                    getActiveEnvelopesRequestState.type,
                activeEnvelopesLoading,

                inactiveEnvelopes,
                getInactiveEnvelopesRequestState:
                    getInactiveEnvelopesRequestState.type,
                inactiveEnvelopesPageNumber:
                    getInactiveEnvelopesRequestData.pageNumber,
                loadNewInactivePage,
                inactiveEnvelopesLoading,

                hideEnvelope,
                hideEnvelopeRequestState: hideEnvelopeRequestState.type,

                showEnvelope,
                showEnvelopeRequestState: showEnvelopeRequestState.type,

                hideNotificationClick,
                showNotification,

                editTarget,

                createEnvelopeClick,
                editEnvelopeClick,
                closeEditForm,

                createEnvelope,
                createEnvelopeRequestState: createEnvelopeRequestState.type,

                updateEnvelope,
                updateEnvelopeRequestState: updateEnvelopeRequestState.type,

                deleteEnvelope,
                deleteEnvelopeRequestState: deleteEnvelopeRequestState.type,

                dndDisabled,
                dragAndDrop,
                updatePrioritiesRequestState: updatePrioritiesRequestState.type,
                movedItemId,
                showCheckMark,
                rollbackDragAndDrop,
            }}
        >
            {children}
        </EnvelopesContext.Provider>
    );
};
