import React, { useMemo, useState, useEffect } from 'react';
import {
    ApiHandler,
    Donee,
    UpdateDoneeResponse,
    UpdateOrganizationInfoRequest,
} from '@givelify/api';
import {
    InvokeApiErrorResponse,
    formatPhoneNumber,
    isFailed,
    isSucceeded,
    useApiRequest,
    useInvokeApi,
} from '@givelify/utils';
import { Grid } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { EditOption } from 'pages/settings/components/EditOption';
import { useAppDispatch } from 'store';
import { setDoneeContact } from 'store/donee/actions';
import { useAdvancedTranslation } from 'utils/i18n';
import { EditButtonContainer, Section, Subtitle } from '../style';
import ContactSectionEditor from './ContactSectionEditor';
import { ContactDetail } from './styles';

type ContactGetResponse = {
    data?: { name: string }[];
};

interface ContactSectionProps {
    donee: Donee;
    isReadOnly: boolean;
}

const ContactSection: React.FCC<ContactSectionProps> = ({
    donee,
    isReadOnly,
}) => {
    const { at, t } = useAdvancedTranslation();

    const copy = useMemo(
        () => ({
            phoneNumber: t(
                'pages.settings.organization-info.contact.phoneNumber',
            ),
            email: t('pages.settings.organization-info.contact.email'),
            size: at('pages.settings.organization-info.contact.size'),
            denomination: t(
                'pages.settings.organization-info.contact.denomination',
            ),
            established: t(
                'pages.settings.organization-info.contact.established',
            ),
        }),
        [t, at],
    );

    const dispatch = useAppDispatch();
    const [editorEnabledState, setEditorEnabledState] = useState(false);
    const [contactData, setContactData] = useState({
        phone: donee.phone || '',
        email: donee.email || '',
        established: donee.established ? donee.established.toString() : '',
        denomination: donee.denomination || null,
        congregationSize: donee.congregationSize || null,
        denominationId: null,
    });
    const [saveError, setSaveError] = useState<string | undefined>(undefined);
    const [denominations, setDenominations] = useState<
        { id: string; name: string }[]
    >([]);

    const [updateRequestState, updateRequest] =
        useApiRequest<UpdateDoneeResponse>();
    const [getRequestState, getRequest] = useInvokeApi<ContactGetResponse>();

    const onSubmit = (newData: UpdateOrganizationInfoRequest) => {
        if (!isEqual(contactData, newData)) {
            updateRequest(
                ApiHandler.instance.donees.updateOrganizationInfo(
                    donee.id,
                    newData,
                ),
            );
            setContactData((val) => {
                return {
                    ...val,
                    denominationId: newData.denominationId,
                };
            });
        } else {
            setEditorEnabledState(false);
        }
    };

    useEffect(() => {
        getRequest('GET', `/denominations`);
    }, [getRequest]);

    useEffect(() => {
        if (isSucceeded(getRequestState)) {
            if (
                getRequestState.response &&
                getRequestState.response.data?.length
            ) {
                const denoms = getRequestState.response.data.filter(
                    (denom) => denom?.name?.length > 0,
                );
                denoms.sort((a, b) => a.name.localeCompare(b.name));
                const newDenominations = denoms.reduce(
                    (acc, current) =>
                        acc.find((item) => item.name === current.name)
                            ? acc
                            : [...acc, current],
                    [],
                );
                const denominationId = newDenominations.find(
                    (denom) => denom.name === contactData.denomination,
                )?.id;
                setContactData({
                    ...contactData,
                    denominationId: denominationId || null,
                });
                setDenominations(newDenominations);
            } else {
                setDenominations([]);
            }
        }
        if (isFailed(getRequestState)) {
            setDenominations([]);
        }
        //listen for changes in getRequestState
        //eslint-disable-next-line
    }, [getRequestState]);

    useEffect(() => {
        if (isSucceeded(updateRequestState)) {
            const updatedData = updateRequestState.response;
            setContactData({
                phone: updatedData.phone,
                email: updatedData.email,
                established: updatedData.established
                    ? updatedData.established.toString()
                    : '',
                denomination: updatedData.denomination,
                congregationSize: updatedData.congregationSize,
                denominationId: contactData.denominationId,
            });
            dispatch(
                setDoneeContact(
                    donee.id,
                    updatedData.phone,
                    updatedData.email,
                    updatedData.established,
                    updatedData.congregationSize,
                    updatedData.denomination,
                    new Date(updatedData.updatedAt),
                ),
            );
            setEditorEnabledState(false);
        }
        if (isFailed(updateRequestState)) {
            setSaveError(
                (updateRequestState as InvokeApiErrorResponse).error.message,
            );
        }
        //listen for changes in updateRequestState
        //eslint-disable-next-line
    }, [updateRequestState]);

    const onEditClick = () => {
        setSaveError(undefined);
        setEditorEnabledState(true);
    };

    const onCancel = () => {
        setEditorEnabledState(false);
        setSaveError(undefined);
    };

    const saving = updateRequestState.type === 'REQUEST_START';
    const loadingDenominations = getRequestState.type === 'REQUEST_START';
    const isChurch = donee?.type === 'church';
    return (
        <Section>
            {editorEnabledState ? (
                <ContactSectionEditor
                    data={contactData}
                    denominations={denominations}
                    error={saveError}
                    isChurch={isChurch}
                    loadingDenominations={loadingDenominations}
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    saving={saving}
                />
            ) : (
                <Grid container>
                    <Grid item md={6} sm={12} xs={12}>
                        <Subtitle text={copy.phoneNumber} variant="heading3S" />
                        <ContactDetail
                            data-testid="org-cont-phone"
                            text={formatPhoneNumber(contactData.phone)}
                            variant="body1"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Subtitle text={copy.email} variant="heading3S" />
                        <ContactDetail
                            data-testid="org-cont-email"
                            text={contactData.email}
                            variant="body1"
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Subtitle text={copy.established} variant="heading3S" />
                        <ContactDetail
                            data-testid="org-cont-est"
                            text={contactData.established}
                            variant="body1"
                        />
                    </Grid>
                    {isChurch && (
                        <>
                            <Grid item md={6} sm={12} xs={12}>
                                <Subtitle
                                    text={copy.denomination}
                                    variant="heading3S"
                                />
                                <ContactDetail
                                    data-testid="org-cont-denom"
                                    text={contactData.denomination}
                                    variant="body1"
                                />
                            </Grid>
                            <Grid item md={6} sm={12} xs={12}>
                                <Subtitle
                                    text={copy.size}
                                    variant="heading3S"
                                />
                                <ContactDetail
                                    data-testid="org-cont-size"
                                    text={contactData.congregationSize}
                                    variant="body1"
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
            <EditButtonContainer>
                {!isReadOnly &&
                    !editorEnabledState &&
                    denominations.length > 0 && (
                        <EditOption
                            onClick={onEditClick}
                            testId="edit-contact-info"
                        />
                    )}
            </EditButtonContainer>
        </Section>
    );
};

export default ContactSection;
