import React from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

type FilterContextType = {
    update: (params: URLSearchParams) => void;
};

const FilterContext = React.createContext<FilterContextType>({
    update: () => {
        throw new Error('Context not initialized');
    },
});

export const useFilterContext = () => React.useContext(FilterContext);

export const FilterProvider: React.FCC = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = React.useState(
        new URLSearchParams(location.search),
    );
    const debouncedUpdateUrl = debounce(() => {
        const pathname = location.pathname;
        navigate(
            { pathname, search: searchParams.toString() },
            { state: { fromFilter: true } },
        );
    }, 100);
    const update = React.useCallback(
        (params: URLSearchParams) => {
            let updated = false;
            params.forEach((value, key) => {
                if (value) {
                    if (searchParams.get(key) !== value) {
                        searchParams.set(key, value);
                        updated = true;
                    }
                } else if (searchParams.has(key)) {
                    searchParams.delete(key);
                    updated = true;
                }
            });
            if (updated) {
                setSearchParams(new URLSearchParams(searchParams));
                debouncedUpdateUrl();
            }
        },
        [searchParams, debouncedUpdateUrl],
    );

    return (
        <FilterContext.Provider value={{ update }}>
            {children}
        </FilterContext.Provider>
    );
};
