import React from 'react';
import { CardsStack } from '@givelify/onboarding';
import { PAGE_NAME, TrackingProvider } from '@givelify/utils';
import PageTitle from 'components/PageTitle';
import { useCalendlyCTAButtonContext } from 'layout/components/header/Dropdowns/CalendlyModal/CalendlyFloatingButton';
import { MainWrapperTopSection } from 'layout/MainWrapperTopSection';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { useGasRouterContext } from 'router/GasRouterProvider';
import { AppActions, AppState } from 'store';
import { updateNotification as updateDoneeNotification } from 'store/doneeNotification/thunks';
import { updateNotification } from 'store/notifications/thunks';
import { Features, isFeatureEnabled } from 'utils/featureGating';
import FirstDonationPanel from './FirstDonationPanel';
import { GivingTuesdayProvider } from './GivingTuesday/context';
import { OverviewMain } from './OverviewMain';

export const OverviewPage: React.FCC = () => {
    const navigate = useNavigate();
    const { PATH } = useGasRouterContext();

    const dispatch: ThunkDispatch<AppState, unknown, AppActions> =
        useDispatch();

    const {
        user,
        donee,
        campuses,
        enabledFeatures,
        startedOnboardingV2,
        hideIntroductionVideoCards,
        hideOnboardingProfileMessage,
        hideOnboardingVerifiedMessage,
    } = useSelector((state: AppState) => ({
        user: state.User.user,
        donee: state.Donee.donee,
        campuses: state.Donee.campuses,
        enabledFeatures: state.System.enabledFeatures,
        startedOnboardingV2: state.DoneeNotifications.startedOnboardingV2,
        hideIntroductionVideoCards:
            state.Notifications.hideIntroductionVideoCards ||
            state.DoneeNotifications.hideVideoCardsModal, // we're hiding this block if campus has at least 2 dontaions
        hideOnboardingProfileMessage:
            state.DoneeNotifications.hideOnboardingProfileMessage,
        hideOnboardingVerifiedMessage:
            state.DoneeNotifications.hideOnboardingVerifiedMessage,
    }));

    const {
        featureRedirectIntegrationEnabled,
        introductionVideoCardsEnabled,
        newOnboardingEnabled,
        captivePortalEnabled,
    } = React.useMemo(
        () => ({
            featureRedirectIntegrationEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.REDIRECT_INTEGRATIONS,
            ),
            introductionVideoCardsEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.OVERVIEW_VIDEO_CARDS,
                false,
            ),
            newOnboardingEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.NEW_ONBOARDING,
                false,
            ),
            captivePortalEnabled: isFeatureEnabled(
                enabledFeatures,
                Features.CAPTIVE_PORTAL,
                false,
            ),
        }),
        [enabledFeatures],
    );

    const showTimeToFirstDonationPanel =
        ((newOnboardingEnabled && startedOnboardingV2) ||
            captivePortalEnabled) &&
        !!donee.onboarding.hasMid &&
        !donee.lastDonationDate;

    const onProfileClose = () => {
        void dispatch(
            updateDoneeNotification('hideOnboardingProfileMessage', true),
        );
    };

    const onVerifiedClose = () => {
        void dispatch(
            updateDoneeNotification('hideOnboardingVerifiedMessage', true),
        );
    };

    const onVideoCardsCloseClick = () => {
        void dispatch(updateNotification('hideIntroductionVideoCards', true));
    };

    const { setButtonBringToFront } = useCalendlyCTAButtonContext();

    if (featureRedirectIntegrationEnabled) {
        navigate(PATH.DATA.ROOT);
        return null;
    }

    const isHQ = donee.id === campuses[0]?.id && !donee.parentCampus;

    return (
        <TrackingProvider trackPageVisit pageName={PAGE_NAME.Overview}>
            <div data-testid="page-overview">
                <PageTitle description="Overview Page" title="Overview Page" />
                <MainWrapperTopSection>
                    {captivePortalEnabled && (
                        <CardsStack
                            donee={donee}
                            hideProfileCard={hideOnboardingProfileMessage}
                            hideVideoCard={hideIntroductionVideoCards}
                            introductionVideoCardsEnabled={
                                introductionVideoCardsEnabled
                            }
                            onCompleteCardClose={onVerifiedClose}
                            onProfileCardClose={onProfileClose}
                            onVideoCardClose={onVideoCardsCloseClick}
                            setButtonBringToFront={setButtonBringToFront}
                            settingsUsersPath={PATH.SETTINGS.USERS()}
                            showCompleteCard={!hideOnboardingVerifiedMessage}
                            user={user}
                        />
                    )}
                    {showTimeToFirstDonationPanel && isHQ && (
                        <FirstDonationPanel />
                    )}
                    <GivingTuesdayProvider />
                </MainWrapperTopSection>
                <OverviewMain />
            </div>
        </TrackingProvider>
    );
};
