import React from 'react';
import { GivelifyNotification } from '@givelify/ui';
import { copyToClipboard } from '@givelify/utils';
import { Skeleton, SnackbarCloseReason, Stack } from '@mui/material';
import clevertap from 'clevertap-web-sdk';

import { SocialMediaName } from '../../types';
import { SNACKBAR_TIMEOUT } from '../../types/const';
import {
    FB_GIVING_COPY_LINK,
    IG_GIVING_COPY_LINK,
    TW_GIVING_COPY_LINK,
    YT_GIVING_COPY_LINK,
} from '../../utils/cleverTapEvents';
import { useTranslations } from '../../utils/translations';
import {
    CopyLinkButton,
    HideOnMobile,
    LinkContainer,
    ShowOnMobile,
    UrlText,
} from './style';

type ButtonVariant = 'primary' | 'secondary';
interface Props {
    media?: SocialMediaName;
    buttonVariant?: ButtonVariant;
    givingLink: string;
}

interface CopyLinkBoxProps {
    event?: string;
    link?: string;
    isLoading?: boolean;
    buttonVariant?: ButtonVariant;
}

const CLEVERTAP_EVENTS: { [x in SocialMediaName]: string } = {
    facebook: FB_GIVING_COPY_LINK,
    twitter: TW_GIVING_COPY_LINK,
    instagram: IG_GIVING_COPY_LINK,
    youtube: YT_GIVING_COPY_LINK,
};

export const CopyLinkBox: React.FCC<CopyLinkBoxProps> = ({
    event,
    link,
    isLoading,
    buttonVariant = 'secondary',
}: CopyLinkBoxProps) => {
    const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
    const { copyOrgLink } = useTranslations('socialMediaGiving.facebook', {
        copyOrgLink: 'copyLink',
    }) as { copyOrgLink: string };

    const handleCopyLinkClick = () => {
        if (event) {
            clevertap?.event.push(event);
        }

        if (link) {
            copyToClipboard(link).then((isSuccess) => {
                if (isSuccess) setIsSnackbarOpen(true);
            });
        }
    };

    const handleCloseSnackbar = (
        _: React.SyntheticEvent<any> | Event,
        reason: SnackbarCloseReason | 'dismiss',
    ) => {
        if (reason === 'clickaway') return;
        setIsSnackbarOpen(false);
    };

    return (
        <>
            <GivelifyNotification
                global
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={SNACKBAR_TIMEOUT}
                onClose={handleCloseSnackbar}
                open={isSnackbarOpen}
                text="Link copied."
                transitionDuration={0}
                variant="success"
            />
            <HideOnMobile>
                <LinkContainer>
                    {isLoading ? (
                        <Skeleton
                            data-testid="online-giving-link-loading-desktop"
                            height={32}
                            width="100%"
                        ></Skeleton>
                    ) : (
                        <UrlText
                            data-testid="online-giving-link-desktop"
                            id="online-giving-link"
                            text={link}
                        />
                    )}
                    <Stack
                        position="relative"
                        width={isLoading ? '30%' : 'auto'}
                    >
                        <CopyLinkButton
                            data-testid="online-giving-link-copy-button"
                            disabled={!!isLoading}
                            loadingIndicator="ring"
                            name="copyLinkDesktop"
                            onClick={handleCopyLinkClick}
                            size="medium"
                            text={copyOrgLink}
                            variant={buttonVariant}
                        />
                    </Stack>
                </LinkContainer>
            </HideOnMobile>
            <ShowOnMobile>
                <LinkContainer>
                    {isLoading ? (
                        <Skeleton
                            data-testid="online-giving-link-loading-mobile"
                            height={40}
                            width="100%"
                        ></Skeleton>
                    ) : (
                        <UrlText
                            data-testid="online-giving-link-mobile"
                            text={link}
                        />
                    )}
                </LinkContainer>
                <CopyLinkButton
                    disabled={!!isLoading}
                    loadingIndicator="ring"
                    name="copyLinkMobile"
                    onClick={handleCopyLinkClick}
                    size="large"
                    text={copyOrgLink}
                    variant={buttonVariant}
                />
            </ShowOnMobile>
        </>
    );
};

const CopyGivingLink: React.FCC<Props> = ({
    media,
    buttonVariant,
    givingLink,
}: Props) => {
    return (
        <CopyLinkBox
            buttonVariant={buttonVariant}
            event={media ? CLEVERTAP_EVENTS[media] : undefined}
            link={givingLink}
        />
    );
};

export default React.memo(CopyGivingLink);
