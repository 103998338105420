import React from 'react';
import { ApiHandler } from '@givelify/api';
import { GivelifyBreadcrumbs } from '@givelify/ui';
import {
    mergeClassNames,
    useApiRequest,
    useMountedState,
} from '@givelify/utils';
import { useTheme, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { useBlocker, Location } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { setDoneePrimaryRep } from 'store/donee/actions';
import { ConfirmDialog } from '../confirmDialog';
import { LoadingDialog } from '../loadingDialog';
import { RepInfoBox } from '../repInfoBox';
import {
    PrimaryRepAddressForm,
    PrimaryRepInfoForm,
    PrimaryRepresentativeInfoStatus,
    apiHiddenSsnRegex,
    apiHiddenSsnRegex11Digits,
} from '../types';
import { PrimaryRepEditorStepAddress } from './addressStep';
import { AddressStepFormRef } from './addressStep/AddressStepForm';
import { PrimaryRepEditorStepInfo } from './infoStep';
import { InfoStepFormRef } from './infoStep/InfoStepForm';
import { Box, EditorContainer, FormBox } from './styles';
import { PrimaryRepEditorStepSummary } from './summaryStep';

type RepEditorStep = 'information' | 'address' | 'summary';

type PrimaryRepEditorProps = {
    doneeId: number;
    information: PrimaryRepInfoForm;
    userDetails: Partial<PrimaryRepInfoForm>;
    address: PrimaryRepAddressForm;
    mode: PrimaryRepresentativeInfoStatus;
    onCancel: (location?: Location | null) => void;
    onEditDone: () => void;
};

export type PrimaryRepEditorRef = {
    isEditing: () => boolean;
    showNotification: (location?: Location | null | string) => void;
};

export const PrimaryRepEditor = React.forwardRef<
    PrimaryRepEditorRef,
    PrimaryRepEditorProps
>(
    (
        {
            doneeId,
            information,
            userDetails,
            address,
            mode,
            onCancel: onEditCancel,
            onEditDone,
        },
        ref,
    ) => {
        const isMounted = useMountedState();
        const { t } = useTranslation();
        const copy = React.useMemo(
            () => ({
                infomation: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.steps.information',
                ),
                address: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.steps.address',
                ),
                summary: t(
                    'pages.settings.bank-info.customize-rep-tab.editor.steps.summary',
                ),
                back: t('labels.back'),
            }),
            [t],
        );
        const dispatch = useAppDispatch();
        const theme = useTheme();
        const isSmallScreen = useMediaQuery(
            theme.breakpoints.down('smallTablet'),
        );
        const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
        const [prevStep, setPrevStep] = React.useState<RepEditorStep>(
            mode === 'add' ? 'information' : 'summary',
        );
        const [activeStep, setActiveStep] = React.useState<RepEditorStep>(
            mode === 'add' ? 'information' : 'summary',
        );
        const [primaryRepSubmitRequest, makePrimaryRepSubmitRequest] =
            useApiRequest<unknown>();
        const [infoStepData, setInfoStepData] = React.useState(information);
        const [addressStepData, setAddressStepData] = React.useState(address);
        const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
        const [loadingState, setLoadingState] = React.useState<
            'loading' | 'success'
        >('loading');
        const [loadingAnimationState, setLoadingAnimationState] =
            React.useState<'idle' | 'playing' | 'completed'>('idle');
        const [loadingTimerIsOn, setLoadingTimerIsOn] =
            React.useState<NodeJS.Timeout | null>(null);
        const [cancelLocation, setCancelLocation] =
            React.useState<null | Location>(null);
        const infoFormRef = React.useRef<InfoStepFormRef>(null);
        const addresFormRef = React.useRef<AddressStepFormRef>(null);
        const onAddressStepBack = React.useCallback(
            (data: PrimaryRepAddressForm) => {
                setAddressStepData(data);
                setActiveStep('information');
                setPrevStep('address');
            },
            [],
        );
        const onSummaryStepBack = React.useCallback(() => {
            setActiveStep('address');
        }, []);
        const onInfoSubmitClick = React.useCallback(
            (data: PrimaryRepInfoForm) => {
                setInfoStepData(data);
                setActiveStep(prevStep === 'summary' ? 'summary' : 'address');
            },
            [prevStep],
        );
        const onAddressSubmitClick = React.useCallback(
            (data: PrimaryRepAddressForm) => {
                setAddressStepData(data);
                setActiveStep('summary');
            },
            [],
        );
        const onConfirmDialogClose = React.useCallback(() => {
            setShowConfirmDialog(false);
            setCancelLocation(null);
        }, []);
        const onCancelConfirm = React.useCallback(() => {
            setShowConfirmDialog(false);
            onEditCancel(cancelLocation);
        }, [cancelLocation, onEditCancel]);
        const infoIsDirty = React.useCallback(() => {
            return (
                information.firstName !== infoStepData.firstName ||
                information.lastName !== infoStepData.lastName ||
                information.title !== infoStepData.title ||
                information.ssn !== infoStepData.ssn ||
                (infoStepData.dateOfBirth && !information.dateOfBirth) ||
                (infoStepData.dateOfBirth &&
                    !dayjs(information.dateOfBirth).isSame(
                        infoStepData.dateOfBirth,
                        'date',
                    ))
            );
        }, [
            infoStepData.dateOfBirth,
            infoStepData.firstName,
            infoStepData.lastName,
            infoStepData.ssn,
            infoStepData.title,
            information.dateOfBirth,
            information.firstName,
            information.lastName,
            information.ssn,
            information.title,
        ]);
        const addressIsDirty = React.useCallback(() => {
            return (
                address.city !== addressStepData.city ||
                address.street !== addressStepData.street ||
                address.state !== addressStepData.state ||
                address.zip !== addressStepData.zip ||
                address.phoneNumber !== addressStepData.phoneNumber
            );
        }, [
            address.city,
            address.phoneNumber,
            address.state,
            address.street,
            address.zip,
            addressStepData.city,
            addressStepData.phoneNumber,
            addressStepData.state,
            addressStepData.street,
            addressStepData.zip,
        ]);
        const isEditing = React.useCallback((): boolean => {
            return (
                infoFormRef.current?.isEditing() ||
                addresFormRef.current?.isEditing() ||
                infoIsDirty() ||
                addressIsDirty()
            );
        }, [addressIsDirty, infoIsDirty]);
        const showNotification = React.useCallback((location: Location) => {
            setCancelLocation(location);
            setShowConfirmDialog(true);
        }, []);
        const onSummaryCancelClick = React.useCallback(() => {
            if (isEditing()) {
                setShowConfirmDialog(true);
            } else {
                onEditCancel();
            }
        }, [isEditing, onEditCancel]);
        const onFormCancelClick = React.useCallback(() => {
            if (isEditing()) {
                setShowConfirmDialog(true);
            } else {
                if (mode === 'update') {
                    setActiveStep('summary');
                } else {
                    onEditCancel();
                }
            }
        }, [isEditing, mode, onEditCancel]);
        const onInfoEditClick = React.useCallback(() => {
            setActiveStep('information');
            setPrevStep('summary');
        }, []);
        const onAddressEditClick = React.useCallback(() => {
            setActiveStep('address');
        }, []);
        const onSubmit = React.useCallback(() => {
            makePrimaryRepSubmitRequest(
                ApiHandler.instance.donees.updatePrimaryRepresentative(
                    doneeId,
                    {
                        city: addressStepData.city,
                        state: addressStepData.state,
                        zip: addressStepData.zip,
                        phoneNumber: addressStepData.phoneNumber,
                        address: addressStepData.street,
                        firstName: infoStepData.firstName,
                        lastName: infoStepData.lastName,
                        title: infoStepData.title,
                        isPrimaryRepresentative:
                            infoStepData.isPrimaryRepresentative ? 1 : 0,
                        dateOfBirth: infoStepData.dateOfBirth?.toISOString(),
                        ssn: infoStepData.ssn.replace(/-/g, ''),
                    },
                ),
            );
        }, [
            infoStepData,
            addressStepData,
            makePrimaryRepSubmitRequest,
            doneeId,
        ]);
        React.useImperativeHandle(ref, () => ({
            isEditing,
            showNotification,
        }));
        useBlocker(({ nextLocation }) => {
            if (nextLocation.state && nextLocation.state.ignoreBlocker)
                return false;
            const shouldBlock = !cancelLocation && isEditing();
            if (shouldBlock) {
                showNotification(nextLocation);
            }
            return shouldBlock;
        });
        React.useEffect(() => {
            if (
                primaryRepSubmitRequest.type === 'REQUEST_START' &&
                loadingTimerIsOn === null
            ) {
                setLoadingAnimationState('playing');
                setLoadingTimerIsOn(
                    setTimeout(() => {
                        if (isMounted) {
                            setLoadingAnimationState('completed');
                            setLoadingState('loading');
                        }
                    }, 4000),
                );
            } else if (
                primaryRepSubmitRequest.type === 'REQUEST_SUCCESS' &&
                loadingAnimationState === 'completed'
            ) {
                setLoadingState('success');
                setLoadingAnimationState('playing');
                setLoadingTimerIsOn(
                    setTimeout(() => {
                        if (isMounted()) {
                            onEditDone();
                        }
                    }, 600),
                );
                dispatch(
                    setDoneePrimaryRep({
                        firstName: infoStepData.firstName,
                        lastName: infoStepData.lastName,
                        title: infoStepData.title,
                        isPrimaryRepresentative:
                            infoStepData.isPrimaryRepresentative,
                        dateOfBirth: infoStepData.dateOfBirth,
                        ssn: infoStepData.ssn,
                        submittedAt: new Date(),
                        address: addressStepData.street,
                        zip: addressStepData.zip,
                        state: addressStepData.state,
                        city: addressStepData.city,
                        phoneNumber: addressStepData.phoneNumber,
                        submittedByOfficialId: doneeId,
                        status: 'verified',
                        retryMessage: '',
                    }),
                );
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [primaryRepSubmitRequest, loadingTimerIsOn, loadingAnimationState]);
        const isInitialSsn = React.useMemo(
            () =>
                apiHiddenSsnRegex.test(infoStepData.ssn) ||
                apiHiddenSsnRegex11Digits.test(infoStepData.ssn),
            [infoStepData.ssn],
        );
        return (
            <EditorContainer>
                <GivelifyBreadcrumbs
                    disableNavigation
                    active={`step-${activeStep}`}
                    className={mergeClassNames(
                        activeStep === 'address' && isMobile && 'addr-mobile',
                        activeStep === 'summary' && isMobile && 'sum-mobile',
                    )}
                    options={[
                        {
                            id: 'step-information',
                            label: copy.infomation,
                            isCompleted: activeStep !== 'information',
                        },
                        {
                            id: 'step-address',
                            label: copy.address,
                            isCompleted: activeStep === 'summary',
                        },
                        {
                            id: 'step-summary',
                            label: copy.summary,
                        },
                    ]}
                />
                <Box>
                    <FormBox>
                        {activeStep === 'information' ? (
                            <PrimaryRepEditorStepInfo
                                formRef={infoFormRef}
                                onCancelClick={onFormCancelClick}
                                onSubmit={onInfoSubmitClick}
                                replace={isInitialSsn}
                                userDetails={userDetails}
                                value={infoStepData}
                            />
                        ) : null}
                        {activeStep === 'address' ? (
                            <PrimaryRepEditorStepAddress
                                formRef={addresFormRef}
                                onBackClick={onAddressStepBack}
                                onCancelClick={onFormCancelClick}
                                onSubmit={onAddressSubmitClick}
                                value={addressStepData}
                            />
                        ) : null}
                        {activeStep === 'summary' ? (
                            <PrimaryRepEditorStepSummary
                                address={addressStepData}
                                canSubmit={infoIsDirty() || addressIsDirty()}
                                hideSsn={!isInitialSsn}
                                info={infoStepData}
                                onAddressEditClick={onAddressEditClick}
                                onBackClick={onSummaryStepBack}
                                onCancelClick={onSummaryCancelClick}
                                onInfoEditClick={onInfoEditClick}
                                onSubmitClick={onSubmit}
                                showBackButton={mode === 'add'}
                            />
                        ) : null}
                    </FormBox>
                    <ConfirmDialog
                        onClose={onConfirmDialogClose}
                        onConfirm={onCancelConfirm}
                        open={showConfirmDialog}
                    />
                    {isSmallScreen || activeStep === 'summary' ? null : (
                        <RepInfoBox />
                    )}
                </Box>
                <LoadingDialog
                    disableBackdropClose
                    open={
                        primaryRepSubmitRequest.type === 'REQUEST_START' ||
                        primaryRepSubmitRequest.type === 'REQUEST_SUCCESS'
                    }
                    status={loadingState === 'loading' ? 'loading' : 'success'}
                />
            </EditorContainer>
        );
    },
);
